import { useAppContext } from "context/app-context";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = ({ Component }) => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  useEffect(() => {
    let token = appContext?.state?.auth;

    if (!token) {
      navigate("/login");
    }
  });
  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
