import React from 'react'

const ServiceCard = ({ item }) => {
  return (
    <div className=" flex-grow w-full sm:basis-[400px] bg-[#f8fafc] hover:bg-[rgb(0,64,128)] hover:text-white  mb-8 text-center flex flex-col gap-3 items-center rounded-sm py-8 px-5 shadow-lg hover:shadow-2xl">
      <div className=" flex justify-center  items-center bg-[rgb(0,64,128)] w-[70px] h-[70px] rounded-full">
        {item.icon}
      </div>
      <h4 className=" text-[20px] font-bold">{item.title}</h4>
      <p className=" text-[#6b7280] hover:text-white">{item.desc}</p>
    </div>
  );
};

export default ServiceCard