import React, { useState } from "react";
import { Link } from "react-router-dom";

import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "components/pageHeading/PageHeading";
import bgForgotPassword from "../assets/img/southend-reviews.png";
import http from "../config/axios";
import toast from "react-hot-toast";
import Joi from "joi";
import Loading from "../components/Shared/Loading";
import { useParams  } from 'react-router-dom'

const schema = Joi.object({
    new_password: Joi.string()
        .min(8)
        .required()
        .messages({
            "string.empty": "New Password is required",
            "string.min": "Password must be minimum 8 characters long",
            "any.required": "New Password is required"
        }),
    confirm_password: Joi.string()
        .min(8)
        .valid(Joi.ref("new_password"))
        .required()
        .messages({
            "string.empty": "Confirm Password is required",
            "any.only": "Password mismatch",
            "any.required": "Confirm Password is required"
        })
});
const ResetPassword = () => {
    const {token} = useParams ()
    const [formData, setFormData] = useState({ new_password: '', confirm_password: ''});
    const [clientError, setClientError] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const { error, value } = schema.validate(formData, { abortEarly: false });

        if (error) {
            setLoading(false);
            setClientError(error.details.map(detail => detail.message).join(', '));
            toast.error(clientError);
            return;
        }

        setLoading(true);
        await http
            .post("/reset-password", { new_password: formData.new_password, confirm_password: formData.confirm_password, reset_token: token})
            .then((res) => {
                setLoading(false);
                toast.success(res.data.success);
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            })
            .catch((err) => {
                err.response.data.error.message ?
                toast.error(err.response.data.error.email) 
                : toast.error(err.response.data.error)
                setLoading(false);
            });
    }
    return (
        <>
            <PageHeading title="Reset Your Password" img={bgForgotPassword} />
            <div className="login-form-wrapper shadow-xl w-full md:w-[60%] lg:w-2/5 mx-auto p-10 mt-20">
                <form className="px-5 py-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col sm:flex-row sm:items-center mb-5">
                        <label className="text-md font-bold w-2/5">New Password</label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                            placeholder="Enter new password"
                            value={formData?.new_password}
                            onChange={(e) => setFormData({ ...formData, new_password: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:items-center mb-5">
                        <label className="text-md font-bold w-2/5">Confirm Password</label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                            placeholder="Confirm password"
                            value={formData?.confirm_password}
                            onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
                        />
                    </div>
                    <button
                        type="submit"
                        className={`simple-gradient-btn uppercase w-full text-white py-3 text-md mt-5 ${loading ? 'disabled' : ''}`}
                    >
                        {
                            loading ? (<Loading height="30" width="30" color="#fff" />) : ' RESET PASSWORD'
                        }
                    </button>
                </form>

                <div className="mt-3 text-center">
                    <Link className="underline" to="/login">
                        Login?
                    </Link>
                </div>
            </div>

            <div className="mt-20">
                <PopularServices />
            </div>
        </>
    );
};

export default ResetPassword;
