import React from 'react'
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/img/southend-city-transfers-logo.png'
import { useEffect } from 'react';
import { useState } from 'react';
import UserDropdown from 'components/Shared/UserDropdown';
import { useAppContext } from 'context/app-context';
import {MdOutlineClose} from 'react-icons/md'
import {RxHamburgerMenu} from 'react-icons/rx'
import ServicPopup from 'components/ServicePopup';


const Header = ({settings}) => {
	    const [showMenu, setShowMenu] = useState(false)
      const [showPopup, setShowPopup] = useState(false)

	const [auth, setAuth] = useState(false);

	const appContext = useAppContext();

	useEffect(() => {
		if (appContext?.state?.auth) {
			setAuth(true);
		} else {
			setAuth(false);
		}
	}, [appContext?.state?.auth]);

	return (
    <div className=" w-full flex flex-col relative shadow-lg shadow-gray-500">
      <div className=" w-full lg:w-[60%] lg:absolute right-0 top-0 flex flex-col md:flex-row md:justify-start ml-auto py-2 gap-3 pl-6 md:rounded-l-full text-white bg-zinc-900 ">
        <Link to="mailto:booking@southendcitytransfer.co.uk" className=" flex items-center gap-3">
          <span className="p-2 bg-gray-700 rounded-full ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="12px"
              height="11px"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              xlink="http://www.w3.org/1999/xlink"
            >
              <g>
                <path
                  fill="#fdc900"
                  d="M -0.5,-0.5 C 3.5,-0.5 7.5,-0.5 11.5,-0.5C 11.5,3.16667 11.5,6.83333 11.5,10.5C 7.5,10.5 3.5,10.5 -0.5,10.5C -0.5,6.83333 -0.5,3.16667 -0.5,-0.5 Z M 2.5,0.5 C 4.60395,0.201097 6.60395,0.534431 8.5,1.5C 5.87429,5.53053 3.87429,5.19719 2.5,0.5 Z M 0.5,2.5 C 2.14772,3.80978 3.81439,5.14311 5.5,6.5C 7.14869,5.35232 8.64869,4.01899 10,2.5C 10.4955,4.4727 10.6621,6.4727 10.5,8.5C 7.16667,8.5 3.83333,8.5 0.5,8.5C 0.5,6.5 0.5,4.5 0.5,2.5 Z"
                />
              </g>
            </svg>
          </span>
          <span>
            <Link className=' text-white' to={`mailto:${settings && settings.email_address ? settings.email_address : 'booking@southendcitytransfer.co.uk'}`}>{settings && settings.email_address ? settings?.email_address : 'booking@southendcitytransfer.co.uk'}</Link>
          </span>
        </Link>
        <Link
                to={`https://api.whatsapp.com/send?phone=${settings && settings.contact_number ? settings.contact_number : '+442080049002'}&text=Hi`}
                className="text-inherit flex items-center gap-3"
              >
          <span className="p-2 bg-gray-700 rounded-full text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="12px"
              height="12px"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              xlink="http://www.w3.org/1999/xlink"
            >
              <g>
                <path
                  fill="#fdc903"
                  d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 3.87099,1.45855 4.87099,3.62522 5.5,6C 7.51826,6.50457 9.51826,7.00457 11.5,7.5C 11.5,8.16667 11.5,8.83333 11.5,9.5C 10.5,9.83333 9.83333,10.5 9.5,11.5C 8.83333,11.5 8.16667,11.5 7.5,11.5C 4.16667,9.5 1.5,6.83333 -0.5,3.5C -0.5,2.83333 -0.5,2.16667 -0.5,1.5C 0.5,1.16667 1.16667,0.5 1.5,-0.5 Z M 1.5,1.5 C 2.28913,1.78281 2.9558,2.28281 3.5,3C 2.18398,3.52811 1.51731,3.02811 1.5,1.5 Z M 7.5,7.5 C 8.28913,7.78281 8.9558,8.28281 9.5,9C 8.18398,9.52811 7.51731,9.02811 7.5,7.5 Z"
                />
              </g>
            </svg>
          </span>
          <span className=' text-white'>{settings && settings.contact_number ? settings?.contact_number : '+442080049002'}</span>
        </Link>
      </div>
      <div className=" flex flex-col lg:flex-row lg:justify-between lg:items-center p-4">
        <div className=" md:w-[40%]" onClick={() => setShowMenu(false)}>
          <Link to="/">
            <img src={settings && settings?.logo ? settings?.logo : logo} alt="" className=" w-[100px] lg:w-fit h-[100px]"/>
          </Link>
        </div>
        <ul
          className={`${
            showMenu ? "block" : "hidden"
          } lg:flex  w-full lg:w-[78%] relative transition-all justify-end mt-4 bg-gray-100 p-4 lg:p-0 lg:rounded-l-full  flex gap-6 lg:gap-8 font flex-col lg:flex-row lg:items-center-Lato lg:py-2 text-[15px] font-[400]`}
        >
          <li onClick={() => setShowMenu(false)}>
            <Link to="/">HOME</Link>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <Link to="/about">ABOUT US</Link>
          </li>
          <li
            className=" flex items-center gap-1 cursor-pointer"
            onMouseEnter={() => setShowPopup(true)}
          >
            <Link to="/services">SERVICES</Link>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
            </svg>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <Link to="/blog">BLOG</Link>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <Link to="/reviews">REVIEWS</Link>
          </li>
          <li onClick={() => setShowMenu(false)}>
            <Link to="/contact">CONTACT</Link>
          </li>
          <li onClick={() => setShowMenu(false)}>
            {auth ? (
              <UserDropdown />
            ) : (
              <Link
                to="/login"
                className="uppercase gradient-login-btn text-white px-6 py-[10px]"
              >
                Login
              </Link>
            )}
          </li>
          {showPopup && (
            <ServicPopup
              setShowPopup={setShowPopup}
              setShowMenu={setShowMenu}
            />
          )}
        </ul>
      </div>
      <div
        className=" block lg:hidden absolute top-28 md:top-20 right-14 z-[2] cursor-pointer"
        onClick={() => setShowMenu(!showMenu)}
      >
        {showMenu ? (
          <MdOutlineClose size={40} />
        ) : (
          <RxHamburgerMenu size={40} />
        )}
      </div>
    </div>
  );
}

export default Header