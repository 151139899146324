import React from 'react';
const data = [
  {
    img: "long-standing-experience.png",
    title: "Long-Standing Experience",
  },
  {
    img: "always-on-time.png",
    title: "Always On Time",
  },
  {
    img: "first-class-services.png",
    title: "First-Class Services",
  },
  {
    img: "all-airport-transfers.png",
    title: "All Airport Transfers",
  },
  {
    img: "top-notch-drivers.png",
    title: "Top-Notch Drivers",
  },
];

const UniqueService = () => {
  return (
    <div className=" w-full flex justify-center mb-10">
      <div className="w-[1100px] flex justify-between flex-wrap">
        {data.map((item, i) => (
          <div
            key={i}
            className=" flex flex-col w-[50%] sm:w-[14%]  items-center justify-center mb-5"
          >
            <div>
              <img
                src={require(`../../assets/img/${data[i].img}`)}
                alt=""
                width={50}
                height={50}
              />
            </div>
            <span className=" text-sm font-[700] pt-3 text-center">
              {item.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniqueService;
