import React from "react";
import { FaStar } from "react-icons/fa6";

import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "../components/pageHeading/PageHeading";
import MainHeading from "../components/mainHeading/MainHeading";
import Review from '../components/reviews/Reviews'
import reviewImg from '../assets/img/southend-reviews.png'
import { Link } from "react-router-dom";

const data = [
  {
    title: "CUSTOMERS FEEDBACK",
    subTitle: "What Customers Say About us",
  },
];

const Reviews = () => {
  return (
    <>
      <PageHeading title="Reviews" img={reviewImg} />
      <div className=" mt-14 mb-14">
        <MainHeading data={data} />
      </div>
      <Review />
      <div className=" text-center mb-14 mt-4">
        <Link
          to="/review"
          className=" uppercase text-center px-6 py-3  rounded-md text-white text-[16px] bg-gradient-to-r from-[#002041] to-[#000810]"
        >
          Write a Review
        </Link>
      </div>
      <PopularServices className="mt-20" />
    </>
  );
};

export default Reviews;
