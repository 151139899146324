import PageHeading from 'components/pageHeading/PageHeading';
import React, { useEffect, useState } from 'react'
import reviewImg from "../assets/img/southend-reviews.png";
import PopularServices from 'components/Popular/PopularServices';
import ReactStars from "react-rating-stars-component";
import toast from "react-hot-toast";
import http from "../config/axios";
import { useNavigate } from 'react-router-dom';

const Review = () => {
      const [orderNo, setOrderNo] = useState("");
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [rating, setRating] = useState(5);
      const [review, setReview] = useState("");
      const [error, setError] = useState(false);
      const [randomNumber, setRandomNumber] = useState(null)
      const [verificationCode, setVerificationCode] = useState("")

      const navigate = useNavigate()

    const ratingChanged = (newRating) => {
      console.log(newRating);
      setRating(newRating)
    };

    useEffect(() => {
    function generateRandomNumber() {
      const randomNum = Math.floor(100000 + Math.random() * 900000);

       setRandomNumber(randomNum.toString());
    }

    generateRandomNumber()
    },[])

      const handleSubmit = (e) => {
        e.preventDefault()

        if(name === "" || email === "" || verificationCode === "") {
               return setError(true)
        }

         const data = {
            orderNo: orderNo,
           name: name,
           email: email,
           rating: rating,
           review: review,
         };

         if(randomNumber === verificationCode) {
           http
             .post("/userReview", data)
             .then((res) => {
               toast.success("Thank you. review added successfully!");
             })
             .catch((err) => {
               toast.error("Something went wrong. try again");
             });
           navigate("/");
         }else {
            toast.error("Incorrect verification code");
         }

         console.log(data);
         }
       
  return (
    <>
      <PageHeading title="Write a Review" img={reviewImg} />
      <div className=" w-full flex justify-center my-12">
        <form
          className=" max-w-[90%] md:max-w-[70%] lg:max-w-[40%] flex flex-wrap p-4 shadow-xl"
          onSubmit={handleSubmit}
        >
          <div className=" flex w-full  flex-col mb-6">
            <label htmlFor="name" className=" text-[16px] font-bold">
              Order Number
            </label>
            <input
              type="text"
              name="order"
              id="order"
              onChange={(e) => setOrderNo(e.target.value)}
              className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
          <div className=" flex w-full  flex-col mb-6">
            <label htmlFor="name" className=" text-[16px] font-bold">
              Full name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
              className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
            />
            {error && name === "" && (
              <span className=" text-red-600 mt-1 font-bold">
                This value is required!
              </span>
            )}
          </div>
          <div className=" flex w-full  flex-col mb-6">
            <label htmlFor="email" className=" text-[16px] font-bold">
              Email address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
            />
            {error && email === "" && (
              <span className=" text-red-600 mt-1 font-bold">
                This value is required!
              </span>
            )}
          </div>
          <div className=" ">
            <span>Rating</span>

            <ReactStars
              onChange={ratingChanged}
              count={5}
              size={40}
              activeColor="#ffd700"
              value={rating}
            />
          </div>

          <div className=" flex w-full flex-col mb-6">
            <label htmlFor="message" className=" text-[16px] font-bold">
              Review
            </label>
            <textarea
              type="text"
              name="message"
              id="message"
              onChange={(e) => setReview(e.target.value)}
              className=" bg-gray-200 h-[150px] text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
            />
          </div>
          <div className=" w-full flex justify-between items-center gap-2 md:gap-6">
            <div className=" flex w-[70%]  flex-col items-start mb-6">
              <label htmlFor="email" className=" text-[16px] font-bold">
                Verification Code
              </label>
              <input
              maxLength={6}
                type="text"
                name="verificationCode"
                id="verificationCode"
                onChange={(e) => setVerificationCode(e.target.value)}
                className=" bg-gray-200 w-full text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
              />
              {error && verificationCode === "" && (
                <span className=" text-red-600 mt-1 font-bold text-center">
                  This value is required!
                </span>
              )}
            </div>
            <div className=" w-[30%] md:w-[20%] bg-gray-200 text-center px-1 md:px-0 py-2 border-black font-bold text-[18px] md:text-[20px]">
             {randomNumber}
            </div>
          </div>
          <div className=" text-center w-full">
            <button
              type="submit"
              className=" uppercase w-[40%]  text-white text-[16px] py-3 bg-gradient-to-r from-[#002041] to-[#000810]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <PopularServices className="mt-20" />
    </>
  );
}

export default Review