import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "config/axios";
import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "components/pageHeading/PageHeading";
import blogImg from '../assets/img/southend-blog.png'
import Loader from "components/Shared/Loader";
import { Pagination } from "components/Shared/Pagination";

const Blog = () => {
  const navigate = useNavigate()

  const [blogs, setBlogs] = useState([]);
  const [links, setLinks] = useState([]);
  const [page, setActivePage] = useState('1');
  const [loading, setLoading] = useState(false);
  const [topArticles, setTopArticles] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true)
      await http.get(`/getBlogs?page=${page}`).then((res) => {
        console.log("res.data.blogs", res.data.blogs);
        setBlogs(res.data.blogs.data);
        setLinks(res.data.blogs);
        setLoading(false);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setTopArticles(blogs.slice(0, 3));
      })
        .catch(err => setLoading(false));
    };

    fetchBlogs();
  }, [page]);


  const parseContent = (singleBlog) => {
    const htmlcontent = singleBlog?.blog_content;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlcontent, "text/html");
    const elementsArray = Array.from(doc.body.childNodes);

    let combinedText = "";

    elementsArray.forEach((element) => {
      combinedText += element.textContent.trim() + " ";
    });

    return combinedText
  }

  const handlePageUpdate = (page) => {
    setActivePage(page);
  }
  return (
    <>
      <PageHeading title="BLOG" img={blogImg} />

      {/* blog-section starts here */}
      <div className="w-full relative px-10 flex flex-col md:flex-row mt-20 mb-20">
        <div className="blog-sidebar flex flex-col w-full md:w-[40%] lg:w-1/5">
          <div className="bg-primary py-10 rounded-md">
            <h3 className="text-white text-md font-bold text-center">
              Top Articles
            </h3>
            <div className="article-wrapper px-3">
              <ul>
                {blogs.map((blog, i) => (
                  <li>
                    <Link to={blog?.blog_slug} className="text-white  mt-4 pb-2 no-underline">{blog?.blog_title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* <div className="tags-wrapper mt-10 bg-primary py-5 rounded-md">
            <h3 className="text-white text-md font-bold text-center pt-5">
              Popular Tags
            </h3>
            <ul className="flex flex-row flex-wrap px-3 pt-5">
              <li className="text-white text-xs shadow-lg bg-blue-900 px-2 py-1 w-1/3 text-center">
                Shuttle
              </li>
              <li className="text-primary text-xs shadow-lg bg-white px-2 py-1 w-1/3 text-center">
                Airport
              </li>
              <li className="text-white text-xs shadow-lg bg-blue-900 px-2 py-1 w-1/3 text-center">
                Taxi
              </li>
              <li className="text-white text-xs shadow-lg bg-blue-900 px-2 py-1 w-1/3 text-center mt-3">
                Travel
              </li>
              <li className="text-white text-xs shadow-lg bg-blue-900 px-2 py-1 w-1/3 text-center mt-3">
                UK
              </li>
              <li className="text-white text-xs shadow-lg bg-blue-900 px-2 py-1 w-1/3 text-center mt-3">
                Shuttle
              </li>
            </ul>
          </div> */}

          <div className="assist-wrapper mt-10 bg-primary py-5 rounded-md">
            <h3 className="text-white text-xl font-bold text-center pt-5 relative z-40">
              Need for some help?
            </h3>
            <div className="flex flex-col items-center mt-5 relative z-40">
              <Link
                to="tel:+442080049002"
                className="flex flex-row justify-between"
              >
                <img
                  src={require("../assets/img/southend-mobile.png")}
                  alt="southend-mobile"
                />
                <span className="text-white pl-2 text-sm">
                  (+442) 0800-49002
                </span>
              </Link>
              <Link
                to="https://api.whatsapp.com/send?phone=+447511110030&text=Hi"
                className="flex flex-row justify-between mt-3"
              >
                <img
                  src={require("../assets/img/southend-whatsapp-contact.png")}
                  alt="southend-whatsapp-contact"
                />
                <span className="text-white pl-2 text-sm">
                  (+442) 0800-49002
                </span>
              </Link>
            </div>
          </div>
        </div>

        {/* DYNAMIC SECTION HERE */}
        <div className="blogs-wrapper ml-0 md:ml-10" style={{ width: "100%" }}>
          {blogs.map((blog, i) => (
            <div key={blog.id} className="blog bg-gray-100 p-5 shadow-lg mt-3">
              <h5 className="blog-title text-black text-[20px] font-bold pb-3">
                {blog.blog_title}
              </h5>
              <div
                // dangerouslySetInnerHTML={{ __html: blog.blog_content }}
                className="blog-description"
              >
                {parseContent(blogs[i]).substring(0, 350)}...
              </div>
              <div className="blog-footer flex flex-row justify-between mt-3">
                <Link
                  to={`/blog/${blog?.blog_slug}`}
                  className="text-custom_yellow underline hover:text-amber-500 cursor-pointer"
                >
                  Read More
                </Link>
                <p className="text-sm">{blog.date}</p>{" "}
                {/* Adjust this based on your actual date field */}
              </div>
            </div>
          ))}
          {loading && (
            <div className="overlay">
              <Loader />
            </div>
          )}
        </div>

        {/* DYNAMIC SECTION END HERE */}
      </div>

      {/* blog-section ends here */}

      {/* Pagination */}
      {
        blogs && blogs.length > 0 && <Pagination links={links} activePage={handlePageUpdate} />
      }

      {/* popular-services-section starts here */}
      <div className="mt-20">
        <PopularServices />
      </div>
      {/* popular-services-section starts here */}
    </>
  );
};

export default Blog;
