import React from "react";

import Hero from "../components/Hero/Hero";
import { BsDot } from "react-icons/bs";
import PopularServices from "../components/Popular/PopularServices";
import DifferentServices from "../components/differentServices/DifferentServices";
import ChooseUs from "../components/chooseUs/ChooseUs";
import Faq from "../components/Faq/FAQ";
import ContactSupport from "components/contactSupport/ContactSupport";
import stationImg from '../assets/img/southend-station-service.png'
import hotelImg from '../assets/img/southend-hotel-service.png'
import safeImg from "../assets/img/southend-safe.png";
import pocketImg from "../assets/img/southend-pocket-friendly.png";
import luxuriousImg from "../assets/img/southend-luxurious.png";
import driverImg from "../assets/img/southend-drivers.png";
import costEffectiveImg from "../assets/img/southend-cost-effective.png";
import comfortImg from "../assets/img/southend-comfort.png";
import { Link } from "react-router-dom";

const chooseusData = [
  {
    img: luxuriousImg,
    title: "Pickup at the airport",
    description:
      "Our driver will meet you at the airport terminal to make things easy for you. Simply walk to the waiting area to meet your driver after you have collected your luggage. We value you and do not want you to be inconvenienced, so we make transportation from the airport to the hotel convenient for you.",
  },
  {
    img: pocketImg,
    title: "Cashless Payment",
    description:
      "We understand that not everyone brings cash with them. As a result, our airport transfer service accepts cashless payments. At the end of your ride, the credit card associated with your account will be charged.",
  },
  {
    img: comfortImg,
    title: "Flight Tracking",
    description:
      "Our first goal is your safety. Our drivers are skilled and fully licensed, ensuring a safe and secure airport pickup experience for you. To ensure your safety, we adhere to all relevant safety regulations.",
  },
  {
    img: safeImg,
    title: "Private and Personalised Service",
    description:
      "Our hotel transfer services are private, you will have the entire car to yourself or your company. This provides for a more personalized experience; we ensure that you have a comfortable vehicle that meets your requirements.",
  },
  {
    img: costEffectiveImg,
    title: "PVariety of Vehicles to Choose From",
    description:
      "We provide a large selection of vehicles to meet all of your needs. Whether you require a premium Mercedes for a business meeting or a minivan for a group, we have you covered.",
  },
  {
    img: driverImg,
    title: "Airport transfer service that is safe and convenient",
    description:
      "We provide seamless transfer services from the hotel to the airport, removing the need to navigate public transportation or haggle with taxi drivers. You don't know anything about taxi drivers, which makes you feel worried but our drivers are subjected to rigorous scrutiny, so you know you're in good hands.",
  },
];
const data = [
  {
    title: "SOUTHEND CITY STATION TRANSFERS",
    subTitle: "Your Reliable Airport and Station Transfer Services",
    img: stationImg,
    text: [
      "Southend City understands how tough it can be to navigate a new city or get to your destination on time. That is why we have structured our transfer services so that you will not have to worry about anything. Our station transfer services can get you to your destination from any London train station. We are a dynamic minicab company with vast expertise in the area; our team of experienced drivers is committed to providing you with high-quality transportation that satisfies all of your requirements.",
    ],
    features: [
      "Hassle-Free Transfer Services:",
      "Comfortable and Cab Service:",
    ],
  },
];

const stations = [
  "London Kings Cross Station",
  'London Bridge Station',
  "St Pancras International Station",
  "London Euston Station",
  "London Waterloo Station",
  'London Marylebone Station',
  "Liverpool Street Station",
  'London Paddington Station',
  'London Victoria Station',
  'London Victoria Coach Station',
  "Stratford Station",
];


const StationData = [
  {
    title: "Benefits of Choosing Southend City for station minicab service:",
    desc: "Our efficient station taxi service ensures that you arrive at your destination safely and on time. No more stressing about finding a taxi or standing in huge lines. We understand that everyone has different travel preferences, which is why our station transfer services allow you to select the car that best suits you. We offer reasonably priced services, and if you don't have cash, you can pay with a credit card or another method. We always have your back because the most important factor for us is you, which is why all of our policies are geared to cater to your needs.So, when you require a reliable taxi from Heathrow to Woking train station, rely on Southend City Transfers to give you a stress-free and delightful ride that includes all of the benefits you deserve.",
  },
  {
    title: "Your London Travel Buddy: Southend City's Got Your Back!",
    desc: "If you arrive by rail in London, you'll need to go from the station to your hotel or other destination. Southend City Station Transfers provides a quick and inexpensive way to get through the city. Our service provides private transport to and from all train terminals in Southend City. Our drivers are competent and skilled, and they will ensure that you arrive safely and on schedule. So, no worries if you are new to London or you are on a tight schedule, we will get you to your destination.",
  },
  {
    title: "Your Ride, Your Way: Book Your Station Transfer Now!",
    desc: "Say goodbye to transportation hassles and hello to convenience and comfort. At Southend City Transfers, we're all about giving you the freedom to choose. From any station, we'll tailor your ride to perfection. Don't settle for less - take control of your journey and book your station transfer now. Your personalized transportation experience awaits!",
  },
];

const faqData = [
  {
    ques: "Southend City Station Transfer Services operate from which train stations?",
    servicse: [
      "London King's Cross Station",
      "St Pancras International Station",
      "London Euston Station",
      "London Waterloo Station",
      "Liverpool Street Station",
      "Stratford Station",
      "London Bridge Station",
      "London Marylebone Station",
      "London Paddington Station",
      "London Victoria Station",
      "London Victoria Coach Station",
    ],
    ans: "We provide services to and from the following London stations:",
  },
  {
    ques: "What vehicles do you have available for airport and train station transfers?",
    servicse: [],
    ans: "We provide a variety of vehicles to meet your needs, including sedans, SUVs, vans, and buses.",
  },
  {
    ques: "Is it possible to schedule a transfer from Kings Cross Station?",
    servicse: [],
    ans: "Absolutely! When making a booking, you can specify the time you want to be picked up from Kings Cross Station. Whether you need to catch a connecting train, attend a business meeting, or simply want to be at your destination on time, we will accommodate your requested time.",
  },
];

const StationService = () => {
  return (
    <>
      <Hero heading="Station Transfers" />

      <DifferentServices data={data} type="station" />
      {/* <ChooseUs data={chooseusData} /> */}
      <Faq data={faqData} title="Station TRANSFERS SERVICE" />
      <ContactSupport />
      <div className="mt-20">
        <PopularServices />
      </div>
      <div className=" w-full flex justify-center py-10 bg-[#f1f5f9]">
        <div className=" w-[70%] flex gap-8 flex-col ">
          <div className=" flex flex-col">
            <span className=" text-[30px] mb-2  font-bold text-center">
              Station Transfer Services:
            </span>
            <p className=" text-center mb-2">
              We provide transport to and from all London tube and train
              stations. Some of our primary transfer station pick-up and
              drop-off locations include:
            </p>
            <div className=" flex flex-wrap">
              {stations.map((item) => (
                <div className=" flex items-center w-[50%] p-0 m-0">
                  <BsDot size={30} />
                  <span className=" text-[15px]">{item}</span>
                </div>
              ))}
            </div>
          </div>
          {StationData.map((item) => (
            <div className=" bg-white shadow-lg hover:bg-[rgb(0,64,128)] hover:text-white p-8 ">
              <span className=" text-[20px] font-bold">{item.title}</span>
              <p>{item.desc}</p>
            </div>
          ))}

          <span className=" text-center font-bold">
            Join the Fast Lane: Book Now and Skip the Wait!
          </span>
        </div>
      </div>
      <div className="mt-10 mb-10 flex justify-center">
        <Link to="/" className="uppercase bg-gradient-to-r from-[#002041] to-[#004080] text-white px-6 py-2 font-semibold">Book Now</Link>
      </div>
    </>
  );
};

export default StationService;
