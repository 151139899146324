import React from 'react'
import { ColorRing } from 'react-loader-spinner'
const Loader = () => {
    return (
      <div>
        <div className=" w-screen h-screen flex items-center z-[9999] justify-center relative bg-[rgba(255, 255, 255, 0.1)]">
        <ColorRing
          visible={true}
          height="50"
          width="50"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#000000"]}
        />
      </div>
      </div>
    );
}

export default Loader