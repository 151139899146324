import React, { useState } from "react";
import { BsDot } from "react-icons/bs";

const FaqItem = ({ item, index }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleClick = () => {
    setShowAnswer(!showAnswer)
    };


  return (
    <div className="rounded flex flex-col gap-1 bg-white rounded-br-[20px] mb-2">
      <div
        onClick={handleClick}
        className="relative flex justify-between items-center cursor-pointer p-2 shadow-lg w-full rounded-br-[20px]"
      >
        <h3 className="text-lg font-bold text-black">{item.ques}</h3>
        <div className="bg-yellow-400 absolute top-0 right-0 flex items-center justify-center w-8 h-full p-2 text-white rounded-br-[20px]">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADcSURBVHgB7ZPLDYMwEETXcAFxoYTQQUogHaQFOqCEUEFKSFJB0kHoJO4ADiDg5IwlkCzL/Dn6SdZi73o0GhkiSw9TN1VVnV3XDWkjvu/nRuG2bQXtQAiRQPwpvx2tkdF2OFY+bJjehesvSkwrgamLGoVjGEhQSlonmqmiEmYahOsU5U7L4J7nRfohG5teGgncRnDL9XNn4sJsJH0E3NRjUxfrur7iXb9H2sYIBkYdS4Ig+MDVy9Aq5SugPRRFESLvn/x5lJXSETRNEw+iXdc96EggfpPOUU9ksWziDyH4c5cOCJSoAAAAAElFTkSuQmCC"
            alt=""
          />
        </div>
      </div>
      {showAnswer && (
        <div className=" text-gray-700 text-[16px] px-4">
          <span className=" w-full mt-2 bg-white  rounded-md">{item.ans}</span>
          <div className={` mt-2 mb-2 `}>
            {item?.servicse.length > 0 &&
              item?.servicse.map((ele, i) => (
                <div className=" flex items-center p-0 m-0" key={i}>
                  <BsDot size={30} />
                  <span>{ele}</span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqItem;

