/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import Ratings from "./Ratings";

const SingleReview = ({ item }) => {
  return (
    <div className=" w-full md:w-[40%] lg:w-1/3 bg-white shadow-lg flex flex-col p-4  mt-14 items-center  gap-6 relative">
      <div className=" absolute top-[-12%]">
        <img src={item.img} alt="" />
      </div>
      <h5 className=" text-[16px] font-bold mt-32">{item.title}</h5>
      <span className=" text-sm text-center text-gray-500 ">
        {item.description}
      </span>
      <div className=" flex">
        <Ratings rating={item.rating} />
      </div>
    </div>
  );
};

export default SingleReview;
