import React from 'react';
// eslint-disable-next-line react/prop-types
const PageHeading = ({ title, img}) => {
  return (
    <div
      className={` w-full relative z-0  flex justify-center items-center h-[50vh] bg-cover bg-no-repeat`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className=" absolute top-0 left-0 h-full w-full z-20 bg-[#002041cc]"></div>
      <h6 className=" text-3xl font-bold z-40 text-white uppercase">{title}</h6>
    </div>
  );
};

export default PageHeading;
