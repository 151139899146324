import React from 'react';
import bgImg from '../../assets/img/aboutus.png'

const AboutFeatures = () => {
  return (
    <div className=" w-full mt-8 mb-[100px]">
      <div className=" w-full mt-14 md:mt-32 flex justify-center ">
        <div className=" lg:max-w-[1100px] ml-4 mr-4 lg:m-0 gap-[20px] lg:gap-[150px] mx-auto flex md:flex-row flex-col justify-between   relative">
          {/* <div className=" hidden lg:block w-[400px] h-[500px] border-[3px] border-[#000]"></div> */}
          <div className="  z-10 w-[100%] lg:w-[50%] flex-1">
            <img
              src={
                bgImg
              }
              alt=""
              className=" w-full lg:w-[100%]"
            />
          </div>
          <div className=" w-[100%] lg:w-[45%] flex flex-col gap-[30px] sm:gap-[40px] font-Lato flex-1">
            <div className=" flex flex-col items-center sm:items-start">
              <h4 className=" uppercase text-[rgb(0,64,128)] text-2xl sm:text-4xl font-bold">
                WE ARE HERE
              </h4>
              <h3 className=" text-black text-xl sm:text-4xl font-bold">
                Make Your Journey Simple And Exciting For You.
              </h3>
            </div>
            <p className=" -mt-4">
              Reliable, executive, and safe transportation services are a rare
              commodity nowadays. The good news is that Southend City transfer
              taxi is just a call away.
            </p>
            <p>
              Ever been in a position where you were running late and had to
              catch a plane at the last minute? Well, Southend City transfer is
              available for you, with a reliable taxi company number for you to
              dial and get your needs sorted.
            </p>
            <p>
              With us, you are assured of timely arrival. The last-minute savior
              indeed!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFeatures;
