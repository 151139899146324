import React from 'react';
import SingleReview from "./SingleReview";
import customer1 from '../../assets/img/southend-customer1.png'
import customer2 from '../../assets/img/southend-customer2.png'
import customer3 from '../../assets/img/southend-customer3.png'

const reviewData = [
  {
    img: customer1,
    title: "Christina Patrick",
    rating: 4,
    description:
      "We used Southend City Transfer to take us from the Dover Cruise terminal to Heathrow. I made the booking a few months prior to our arrival.",
  },
  {
    img: customer2,
    title: "Stephen Hawking",
    rating: 2,
    description:
      "Southend City Transfer followed up immediately and just prior to our arrival. A very good experience!",
  },
  {
    img: customer3,
    title: "Christina Patrick",
    rating: 4,
    description:
      "The driver arrived on time to pick us up in a clean and relatively new van and delivered us safely to Heathrow.!",
  },
];

const Review = () => {
  return (
    <div className=" w-full  relative z-[-999] flex items-center justify-center pt-8 pb-8">
      <div className=" absolute w-full h-[30vh] top-0 right-0 z-[-99]">
        <img
          src={require('../../assets/img/southend_customers_reviews.png')}
          alt=""
          className=" object-cover w-full h-full"
        />
        <div className=" absolute top-0 right-0 w-full h-full bg-[#002041cc]"></div>
      </div>
      <div className=" flex w-[80%] flex-wrap lg:flex-nowrap  justify-center lg:justify-between gap-12">
        {reviewData.map((item, i) => (
          <SingleReview item={item} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Review;
