import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import http from "config/axios";


const Main = ({ children }) => {

  const[settings, setSettings] = useState({}); 

  useEffect( () => {
    const fetchSettings = async () => {
      await http
      .get("/settings")
      .then((res) => {
        setSettings(res.data.settings);
      })
      .catch((err) => console.log(err));
    }

    fetchSettings();
  }, []);

  return (
    <>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {/* Common header */}
      <Header settings={settings}/>
      {/* <Menu /> */}

      {/* Main content */}
      <main>{children}</main>

      {/* Common footer */}
      <Footer settings={settings}/>
    </>
  );
};

export default Main;
