/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import MainHeading from "../mainHeading/MainHeading";

const DifferentServices = ({ data, type }) => {
  return (
    <div className=" w-full flex items-center flex-col mt-32">
      <MainHeading data={data} />
      <div className=" max-w-[1150px] p-6 flex flex-wrap lg:flex-nowrap justify-center lg:justify-between  gap-10 mt-2">
        <div className=" w-full lg:w-[48%]">
          <img src={data[0].img} alt="" className=" w-full object-cover" />
        </div>
        <div className=" w-full lg:w-[52%]">
          <div className=" flex flex-col gap-4">
            {data[0].text.map((item, i) => (
              <p key={i}>{data[0].text[i]}</p>
            ))}
          </div>
          {type === "airport" || type === "station" ? (
            <div className=" flex gap-4 w-full flex-wrap justify-between mt-10">
              {data[0].features.map((item, i) => (
                <div
                  key={i}
                  className=" flex gap-3 items-center w-full md:w-[48%]"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="29px"
                      height="29px"
                      // eslint-disable-next-line react/no-unknown-property
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M -0.5,14.5 C -0.5,14.1667 -0.5,13.8333 -0.5,13.5C 2.50156,1.33248 9.83489,-2.33419 21.5,2.5C 21.0004,3.88053 20.0004,4.5472 18.5,4.5C 10.3059,2.59806 5.30588,5.76473 3.5,14C 6.32019,23.7143 12.1535,26.2143 21,21.5C 22.6398,19.421 23.8064,17.0876 24.5,14.5C 25.325,13.6143 26.325,13.281 27.5,13.5C 25.8818,26.3188 18.8818,30.4854 6.5,26C 2.45582,23.0571 0.12249,19.2238 -0.5,14.5 Z"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M 25.5,3.5 C 27.2726,4.31431 27.7726,5.64764 27,7.5C 23.5349,11.9668 19.7016,16.1334 15.5,20C 12.5638,19.1069 10.0638,17.2736 8,14.5C 7.3987,13.0347 7.8987,12.0347 9.5,11.5C 11.1487,12.6477 12.6487,13.981 14,15.5C 18.037,11.631 21.8704,7.63104 25.5,3.5 Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <span className=" font-[600] text-[15px]">
                    {data[0].features[i]}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DifferentServices;
