import React from "react";
import Hero from "../components/Hero/Hero";
import PopularServices from "../components/Popular/PopularServices";

import Services from "../components/Services/Services";
import Testimonial from "../components/testimonial/Testimonial";
import Feature from "components/Features/Features";


const Home = () => {
 
  return (
    <>
      <Hero heading="Southend City Transfers" />
      <div className=" mb-[140px]">
        <Feature />
      </div>
      <Services />
      <div className="mt-20">
        <PopularServices />
      </div>
      <Testimonial />
    </>
  );
};

export default Home;
