import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { useAppContext } from "context/app-context";
import { useEffect } from "react";
import http from "config/axios";
import { useNavigate, } from "react-router-dom";
import BookingHeader from "components/Shared/BookingHeader";
import defaultImg from '../assets/img/defaultCar.png'
import Loader from "components/Shared/Loader";
import { FaUsers } from "react-icons/fa";
import { FaSuitcase } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import toast from "react-hot-toast";

const Vehicle = () => {

  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const appContext = useAppContext();
  const [booking, setBooking] = useState({
    pickup_lat_lon: appContext?.state?.booking?.pickup_lat_lon,
    dropoff_lat_lon: appContext?.state?.booking?.dropoff_lat_lon,
    pickup_location: appContext?.state?.booking?.pickup_location,
    dropoff_location: appContext?.state?.booking?.dropoff_location,
    fare: appContext?.state?.booking?.fare,
    rdJourneyCategory: appContext?.state?.booking?.rdJourneyCategory,
    pickup_date: appContext?.state?.booking?.pickup_date,
    pickup_hour: appContext?.state?.booking?.pickup_hour,
    pickup_minute: appContext?.state?.booking?.pickup_minute,
    distance: appContext?.state?.booking?.distance,
    duration: appContext?.state?.booking?.duration,
    isReturn: appContext?.state?.booking?.isReturn,
    pickup_postal_code: appContext?.state?.booking?.pickup_postal_code,
    dropoff_postal_code: appContext?.state?.booking?.dropoff_postal_code,
  });


  const [vehicles, setVehicles] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isPackage, setIsPackages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!booking?.pickup_lat_lon || !booking?.dropoff_lat_lon) {
      navigate("/");
    }
  }, [booking?.pickup_lat_lon, booking?.dropoff_lat_lon, navigate]);

  useEffect(() => {
    if (booking?.rdJourneyCategory === "hourly") {
      setIsPackages(true);
    }
  }, [booking?.rdJourneyCategory]);

  useEffect(() => {
    if (isPackage) {
      getPackages();
    }
  }, [isPackage]);

  const getPackages = async () => {
    await http
      .get("/getPackages")
      .then((res) => {
        setPackages(res.data.packages);
      })
      .catch((err) => console.log(err));
  };


  // const calculateDistanceAndTime = (maps, directionsService) => {
  //   const pickup = booking?.pickup_lat_lon.split(",");
  //   const pickupLocation = new maps.LatLng(pickup[0], pickup[1]);
  //   const dropoffs = booking?.dropoff_lat_lon.map((dropoff) =>
  //     dropoff.split(",")
  //   );

  //   const calculateRoute = (origin, destination) => {
  //     return new Promise((resolve, reject) => {
  //       directionsService.route(
  //         {
  //           origin: origin,
  //           destination: destination,
  //           travelMode: "DRIVING",
  //         },
  //         (response, status) => {
  //           if (status === "OK") {
  //             const distanceText = response.routes[0].legs[0].distance.text;
  //             const distanceInKm = parseFloat(distanceText.replace(" km", ""));
  //             const distanceInMeters = distanceInKm * 1000;
  //             const distanceInMiles = distanceInMeters * 0.00062137;
  //             const durationText = response.routes[0].legs[0].duration.text;

  //             setDistance(distanceInMiles.toFixed(2));
  //             resolve({ distance: distanceInMiles, duration: durationText });
  //           } else {
  //             reject("Error calculating distance");
  //           }
  //         }
  //       );
  //     });
  //   };

  //  const calculateRoutesSequentially = async () => {
  //    let totalDistance = 0;
  //    let totalDurationInMinutes = 0;

  //    for (let i = 0; i < dropoffs.length; i++) {
  //      const dropoffLocation = new maps.LatLng(dropoffs[i][0], dropoffs[i][1]);
  //      try {
  //        const { distance, duration } = await calculateRoute(
  //          i === 0
  //            ? pickupLocation
  //            : new maps.LatLng(dropoffs[i - 1][0], dropoffs[i - 1][1]),
  //          dropoffLocation
  //        );

  //        totalDistance += parseFloat(distance);

  //        // Parsing duration into hours and minutes
  //        const durationParts = duration.split(" ");
  //        let durationHour = 0;
  //        let durationMinute = 0;

  //        if (durationParts.length === 4) {
  //          // Duration format is like "X hours Y mins"
  //          durationHour = parseInt(durationParts[0]);
  //          durationMinute = parseInt(durationParts[2]);
  //        } else if (durationParts.length === 2 && durationParts[1] === "mins") {
  //          // Duration format is like "Y mins"
  //          durationMinute = parseInt(durationParts[0]);
  //        } else if (durationParts.length === 2 && durationParts[1] === "hour") {
  //          // Duration format is like "X hour"
  //          durationHour = parseInt(durationParts[0]);
  //        }

  //        totalDurationInMinutes += durationHour * 60 + durationMinute;
  //      } catch (error) {
  //        setDistance("Error calculating distance");
  //        setDuration("Error calculating duration");
  //        return;
  //      }
  //    }

  //    // Convert total duration back to hours and minutes
  //    const totalDurationHour = Math.floor(totalDurationInMinutes / 60);
  //    const totalDurationMinute = totalDurationInMinutes % 60;

  //    const totalDuration = `${totalDurationHour} hours ${totalDurationMinute} mins`;

  //    // Update context and local storage
  //    const existingDataString = localStorage.getItem("persist");
  //    const existingData = existingDataString
  //      ? JSON.parse(existingDataString)
  //      : {};
  //    existingData.booking = booking;
  //    existingData.booking.distance = totalDistance.toFixed(2);
  //    existingData.booking.duration = totalDuration;
  //    localStorage.setItem("persist", JSON.stringify(existingData));

  //    appContext.dispatch({
  //      type: appContext.actions.UPDATE,
  //      data: {
  //        ...appContext.state,
  //        booking: {
  //          ...appContext.state.booking,
  //          distance: totalDistance.toFixed(2),
  //          duration: totalDuration,
  //        },
  //      },
  //    });

  //    // Set distance and duration states
  //    setDistance(totalDistance.toFixed(2));
  //    setDuration(totalDuration);
  //  };


  //   calculateRoutesSequentially();
  // };

  // Adjusted calculateDistanceAndTime function
  // Assume this function is part of your React component
  const calculateDistanceAndTime = (maps, directionsService) => {
    const pickup = booking.pickup_lat_lon.split(",");
    const pickupLocation = new maps.LatLng(parseFloat(pickup[0]), parseFloat(pickup[1]));
    const dropoffLocations = booking.dropoff_lat_lon.map(dropoff => {
      const coords = dropoff.split(",");
      return new maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
    });

    let totalDistanceInMeters = 0;
    let totalDurationInSeconds = 0;

    const processNextSegment = (index) => {
      if (index >= dropoffLocations.length) {
        const totalDistanceInMiles = (totalDistanceInMeters / 1609.34).toFixed(2);
        const totalDurationInMinutes = Math.floor(totalDurationInSeconds / 60);
        const hours = Math.floor(totalDurationInMinutes / 60);
        const minutes = totalDurationInMinutes % 60;
        const formattedDuration = `${hours} hrs, ${minutes} mins`;
        setDistance(totalDistanceInMiles);
        setDuration(formattedDuration);
         // Update context and local storage
         const existingDataString = localStorage.getItem("persist");
         const existingData = existingDataString
           ? JSON.parse(existingDataString)
           : {};
         existingData.booking = booking;
         existingData.booking.distance = distance;
         existingData.booking.duration = duration;
         localStorage.setItem("persist", JSON.stringify(existingData));

         appContext.dispatch({
           type: appContext.actions.UPDATE,
           data: {
             ...appContext.state,
             booking: {
               ...appContext.state.booking,
               distance: distance,
               duration: duration,
             },
           },
         });
        return;
      }

      const origin = index === 0 ? pickupLocation : dropoffLocations[index - 1];
      const destination = dropoffLocations[index];

      directionsService.route({
        origin,
        destination,
        travelMode: 'DRIVING',
      }, (response, status) => {
        if (status === 'OK') {
          totalDistanceInMeters += response.routes[0].legs[0].distance.value;
          totalDurationInSeconds += response.routes[0].legs[0].duration.value;
          processNextSegment(index + 1); // Process the next segment
        } else {
          console.error('Directions request failed due to ', status);
        }
      });
    };

    processNextSegment(0); // Start the calculation with the first segment
  };

  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    calculateDistanceAndTime(maps, directionsService);
  };

  const handleState = async (fare, isreturn) => {
    // localStorage.setItem("fare", fare);
    const existingDataString = localStorage.getItem("persist");
    const existingData = existingDataString
      ? JSON.parse(existingDataString)
      : {};
    existingData.booking = booking;
    existingData.booking.fare = fare;
    localStorage.setItem("persist", JSON.stringify(existingData));

    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        ...appContext.state,
        booking: {
          ...appContext.state.booking,
          fare: fare,
          isReturn: isreturn
        },
      },
    });
    navigate("/booking/details", { state: { isreturn } });
  };

  useEffect(() => {
    if (distance !== 0) {
      handleVechiles();
    }
  }, [distance, booking?.pickup_location, booking?.dropoff_location]);

  const handleVechiles = () => {
    setLoading(true);
    http
      .post("/getVehicles", {
        distance,
        pickup_location: booking?.pickup_location,
        dropoff_location: booking?.dropoff_location,
        pickup_postal_code: booking?.pickup_postal_code,
        dropoff_postal_code: booking?.dropoff_postal_code,
        rdJourneyCategory: booking?.rdJourneyCategory,
        hourlyPackage: selectedPackage
      })
      .then((res) => {
        if(res.data?.message){
          toast.error(res.data?.message);
        }else{
          setVehicles(res.data.vehicles);
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  useEffect(() => {
    if(selectedPackage){
      handleVechiles();
    }
  }, [selectedPackage]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_KEY,
            }}
            defaultCenter={{ lat: 0, lng: 0 }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          ></GoogleMapReact>
          <div className="w-full flex flex-col items-center mt-20">
            <BookingHeader
              pickup_location={booking?.pickup_location}
              dropoff_location={booking?.dropoff_location}
              distance={distance}
              duration={duration}
            ></BookingHeader>

            {/* {packages} */}

            {isPackage && (
              <div className="">
                <select className="form-select border rounded py-1 px-2 w-full" value={selectedPackage} onChange={(event) => setSelectedPackage(event.target.value)}>
                  {packages &&
                    packages.map((item, index) => (
                      <option key={item.id} className={selectedPackage == item?.id ? 'selected' : ''} value={item.id}>
                        {`${item.distance} Miles`} {`${item.hours} Hours`}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {/* vehicle */}
            <div className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 mb-10">
              {vehicles &&
                vehicles.map((item) => (
                  <>
                    <div
                      className="col-span-1 md:col-span-1 lg:col-span-1 xl:w-[350px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                      key={item.id}
                    >
                      <div className="card mb-3 flex flex-col">
                        <div className="card-header bg-gray-100">
                          <h4 className="text-center py-3">{item.name}</h4>
                        </div>
                        <div className=" p-1">
                          <div className=" flex items-center justify-center">
                            <img
                              src={
                                `${process.env.REACT_APP_BACKEND_URL}${item.image}` ||
                                defaultImg
                              }
                              className="w-[100%] h-[200px] scale-[0.8] object-contain"
                              alt={item.name}
                            />
                          </div>
                          <div className="flex justify-between mt-3 h5 border bg-gray-200 rounded p-2">
                            {item?.passenger_capacity > 0 && (
                              <span
                                className="text-gray-500  font-bold flex gap-2 items-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Passenger"
                              >
                                <FaUsers size={24} />
                                {item?.passenger_capacity}
                                <span className=" h-full border-l ml-1 border-gray-400"></span>
                              </span>
                            )}

                            {item?.child_seats_capacity > 0 && (
                              <span
                                className="text-gray-500 flex items-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Child Seat"
                              >
                                <span className="me-2">
                                  <img
                                    src="https://www.southendcitytransfer.co.uk/assets/images/child-seat.png"
                                    alt="L"
                                    style={{ height: "18px" }}
                                  />
                                </span>
                                {item?.child_seats_capacity}
                                <span className=" h-full border-l ml-1 border-gray-400"></span>
                              </span>
                            )}

                            {item?.large_luggage_capacity > 0 && (
                              <span
                                className="text-gray-500 font-bold flex gap-2 items-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Check-in luggage"
                              >
                                <FaSuitcase size={20} />
                                {item?.large_luggage_capacity}
                                <span className=" h-full  ml-1 border-l border-gray-400"></span>
                              </span>
                            )}

                            {item?.small_luggage_capacity > 0 && (
                              <span
                                className="text-gray-500 font-bold flex gap-2 items-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Hand luggage"
                              >
                                <FaBriefcase />
                                {item?.small_luggage_capacity}
                              </span>
                            )}
                          </div>
                          <div className="caption-text mt-2 text-center">
                            <button
                              className="text-white w-full bg-cyan-500 text-lg font-bold block mt-4 rounded-md cursor-pointer"
                              onClick={() => {
                                handleState(item.journey_fare, false);
                              }}
                            >
                              <div>ONE WAY: £{item.journey_fare}</div>
                              BOOK NOW
                            </button>
                          </div>
                          {
                            booking?.rdJourneyCategory != 'hourly' && <div className="caption-text mt-2 text-center">
                              <button
                                className="text-white w-full bg-gray-700 text-lg font-bold block mt-4 rounded-md cursor-pointer"
                                onClick={() => {
                                  handleState(item.journey_fare, true);
                                }}
                              >
                                <div>RETURN: £{item.journey_fare * 2}</div>
                                BOOK NOW
                              </button>
                            </div>
                          }
                          {/* <div className="caption-text mt-2 text-center">
                      <Link
                        to="/booking/details"
                        className="text-white w-full bg-slate-500 rounded-lg text-lg font-bold block mt-4"
                      >
                        <div>RETURN: £{item.journey_fare * 2}</div>
                        BOOK NOW
                      </Link>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Vehicle;
