import http from 'config/axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const TopArticles = () => {
  const [topArticle, setTopArticle] = useState([])

  const navigate = useNavigate()

  useEffect(() => {

        const fetchBlogs = async () => {
          await http
            .get("/getBlogs?page=1")
            .then((res) => setTopArticle(res.data.blogs.data))
            .catch((err) => console.log(err));
        };

        fetchBlogs();
  },[])

  const topFiveArticle = topArticle?.slice(0,5);

console.log(topFiveArticle);


  return (
    <div className=" flex-1 shadow-lg h-fit border border-[#babcbe] pt-4 ">
      <h2 className="text-[24px] tracking-wider text-[#111827 font-bold text-center pb-2">
        Top articles
      </h2>
      <hr className=" mb-6" />

      {topFiveArticle.map((blog, i) => (
        <Link
          to={`/blog/${blog?.blog_slug}`}
          key={i}
        >
          <p className=" text-[15px] cursor-pointer mx-3 tracking-wide mb-4 text-[#1f2937]">
            {blog?.blog_title}
          </p>
        </Link>
      ))}
    </div>
  );
}

export default TopArticles