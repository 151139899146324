import Loader from 'components/Shared/Loader'
import BlogDetailsTitle from 'components/blog/BlogDetailsTitle'
import BlogInfo from 'components/blog/BlogInfo'
import TopArticles from 'components/blog/TopArticles'
import http from 'config/axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState()
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true)
      await http
        .get(`/blogDetail/${id}`)
        .then((res) => {
          setLoading(false)
          setBlogDetails(res.data.blogs)
        })
        .catch((err) => setLoading(false));
    };

    fetchBlogs();
  }, [id]);

  return (
    <div className=" w-full ">
      <BlogDetailsTitle
        title={blogDetails && blogDetails[0].blog_title}
        createdAt={blogDetails && blogDetails[0].created_at}
      />
      <div className=" max-w-[1200px] mx-auto mt-12 flex flex-col lg:flex-row p-6 gap-[30px]">
        <BlogInfo blogDetail={blogDetails && blogDetails[0]?.blog_content} />
        <TopArticles />
      </div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default BlogDetails