import React from 'react'


const bookingData = [
  {
    title: "Visit our website",
    desc: "Start by going to our website to access our booking platform. Our airport to hotel booking platform has been built to be user-friendly and optimized for a seamless booking experience.",
  },
  {
    title: "Choose your location",
    desc: "Please specify the airport at which you will arrive. We provide hotel transfer services from major airports such as Heathrow, Gatwick, Stansted, Luton, and others. Choose your airport from the list with caution.",
  },
  {
    title: "Enter Your Travel Details",
    desc: "Provide your travel information, including the day and time of arrival. This will allow us to track your flight so that you do not have to rebook if it is delayed.",
  },
  {
    title: "Select Your Vehicle Type",
    desc: "We provide a variety of vehicles of all kinds. Choose a car from our hotel transfer fleet based on your requirements.",
  },
  {
    title: "Choose your Drop Off",
    desc: "Provide the address or name of your hotel, as well as the particular spot where you want to be dropped off. This allows us to plan the most efficient path for your transfer.",
  },
  {
    title: "Enter Your Contact Info",
    desc: "Provide your contact information, such as phone number, email address, and name. This enables us to contact you in the event of any modifications or updates to your booking.",
  },
];

const BookingProcess = () => {
  return (
    <div>
      <div className="bg-[#e2e8f0] py-8">
        <div className="mt-12 p-3 md:max-w-[70%] mx-auto text-center">
          <span className=' text-[30px] font-bold'>Hotel Transfers Simple Booking Process</span>
          <p className=' mt-3 mb-10'>
            Booking a hotel transfer with Southend City is quick and easy.
            Simply follow the steps below:
          </p>
          <div className="  flex gap-8 flex-wrap">
            {bookingData.map((item) => (
              <div className=" bg-white hover:bg-[rgb(0,64,128)] hover:text-white p-8 flex-grow basis-[100%] md:basis-[40%]">
                <span className=" text-[20px] font-bold">{item.title}</span>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingProcess