import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const Loading = ({height, width, color}) => {
  return (
    <ColorRing
          visible={true}
          height={height}
          width={width}
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          color={color}
        />
  )
}

export default Loading