import React from 'react';
import { Link } from "react-router-dom";

const ContactSupport = () => {
  return (
    <div className=" w-full flex flex-col items-center mt-20">
      <div className=" flex flex-col items-center w-full gap-1 md:gap-0 lg:max-w-[50%]">
        <h5 className=" uppercase text-[18px] md:text-xl text-center font-bold tracking-wider text-[rgb(0,64,128)]">
          HOLD UP!
        </h5>
        <h3 className=" uppercase text-2xl md:text-3xl text-center font-bold leading-8 md:leading-10 tracking-wider">
          HERE IS THE MOST IMPORTANT PART
        </h3>
      </div>
      <div className=" w-full p-4 md:w-[60%] flex flex-col lg:flex-row justify-center mt-6 gap-12 ">
        <div className="w-full lg:w-[50%] flex flex-col">
          <p>
            Thank you for visiting our website! We would be beyond delighted to
            see your name on our booking list anytime soon. But it’s worth the
            wait.
          </p>
          <Link
            to="/contact"
            className=" mt-4 bg-gradient-to-r from-[#002041] to-[#004080] text-center uppercase px-4 py-3 text-white w-[120px]"
          >
            Contact
          </Link>
        </div>
        <div className=" w-full lg:w-[50%]">
          <img src={require('../../assets/img/southend-contact-support.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ContactSupport;
