import React from 'react'
import contactImg from "../assets/img/southend-aboutus.png";
import PageHeading from 'components/pageHeading/PageHeading';
import MainHeading from 'components/mainHeading/MainHeading';
import ServiceCard from 'components/serviceCard/ServiceCard';
import { FaRegStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { IoHappyOutline } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegHandshake } from "react-icons/fa6";
import { FaRegThumbsUp } from "react-icons/fa";
import Bookme from 'components/bookMe/BookMe';
import PopularServices from 'components/Popular/PopularServices';



const data = [
  {
    title: "SOUTHEND CITY  TRANSFERS",
    subTitle: "Getting the Best in Airport Taxi Service at Heathrow",
  },
];

const cardData = [
  {
    icon: <FaRegStar size={40} fill="#fff" />,
    title: "Private Transfer Specialists",
    desc: "Southend City transfer is the airport transfer service provider that has managed the highly interactive and simple-to-use taxi booking website.",
  },
  {
    icon: <IoIosSearch size={40} fill="#fff" />,
    title: "Simple Booking System",
    desc: "Southend City transfer booking website has the simplest cab booking services. Here is the 3-step process that you have to follow to book your next cab.",
  },
  {
    icon: <IoHappyOutline size={40} fill="#fff" color="#fff" />,
    title: "100% Customer Satisfaction",
    desc: "Our customer is our biggest asset. We will assure you that you will be satisfied with the services that you acquire.",
  },
  {
    icon: <MdOutlineWatchLater size={40} fill="#fff" />,
    title: "Waiting Time",
    desc: "Our customer is our biggest asset. We will assure that you will be satisfied with the services that you acquire.",
  },
  {
    icon: <FaRegHandshake size={40} fill="#fff" />,
    title: "Meet And Greet Service",
    desc: "All pickups from the airport come with a Meet and Greet service, which will allow you to easily get out of the airport and be in the cab.",
  },
  {
    icon: <FaRegThumbsUp size={40} fill="#fff" />,
    title: "Special Services",
    desc: "All our services are available at the most affordable rate to assure that you will not have to worry about your budget.",
  },
];

const expectationData = [
  "As far as Southend airport transfers go, you can get the iconic black London cab or rely on the far better online cab services. Matter of fact, better services like Southend City Transfers exist solely to give the customer a pleasant and comfortable ride. Therefore, you should definitely look into online cab services.",
  "With a good minicab at Heathrow Airport, you can expect a couple of things: for one, you can expect it to be very cost-efficient. This is one of the things that entice a passenger to go for such services, as the other options might not be very well appointed.",
  "Secondly, there’s the trip itself. With regular cabs, the trip further adds to the jetlag and tiredness. The cabs aren’t all that comfortable, and sometimes the cabbie isn’t well-versed in the ways around town. You’ve got to look for that as well, so you don’t end up playing the part of the Google Maps lade.",
  "Another thing to look for in a minicab service for airports is whether or not they offer cross-town service, like the popular London Southend to Heathrow route, which is frequented by so many people and is quite a long distance. If you’re running for the airport, first of all, you would want a driver who can bend the rules a little; drive just fast enough to get you to your flight in time.",
];

const Services = () => {
  return (
    <div>
      <PageHeading title="Services" img={contactImg} />
      <div className=" mt-20 p-4">
        <MainHeading data={data} />
        <p className=" w-full p-3 md:max-w-[70%] text-center mx-auto mt-5">
          Southend City transfer Taxi is the leading taxi booking website that
          has been helping tourists and residents to find reliable airport
          transport services. We have been working in the market for many years,
          we understand the needs & demands of customers and also the
          expectations of taxi drivers.
        </p>
      </div>
      <div className=" w-full p-3 md:max-w-[70%] gap-12 mt-[70px] mx-auto flex justify-between flex-wrap">
        {cardData.map((item, i) => (
        
          <ServiceCard item={item} />
        ))}
      </div>
      <div className="services-wrapper py-12">
        <div
          className={` w-full p-3 md:max-w-[70%] text-center mx-auto text-white`}
        >
          <span className=" text-[30px]">Driven to Exceed Expectations</span>
          <p className="  mt-5">
            Unless you’re a person afflicted with wanderlust, traveling isn’t
            for everybody. In fact, many get cranky if you ask them to sit for
            another hour or two, just to get to their destination. And an
            airport is no better place than to be mad at traveling. Jet-lagged,
            tired and disheveled people have to contend with cabbies to get to
            their destination, and it can get unpleasant. Which is why, getting
            the best airport taxi services at Heathrow should be planned well
            before you land, and this article will guide you through that and
            more.
          </p>
        </div>
        <div className=" mt-12 w-[90%] sm:max-w-[70%] mx-auto flex gap-8 flex-wrap">
          {expectationData.map((item) => (
            <div className=" bg-white p-8 flex-grow  basis-[100%] md:basis-[40%]">
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-[#f8fafc]">
        <div className=" my-12 w-full p-3 md:max-w-[70%] mx-auto flex flex-col gap-10 text-center">
          <span className=" text-[24px] md:text-[30px]">
            Everything We Provide is Driven by You
          </span>
          <p className="  ">
            Many commuters take the transfers to Heathrow Airport option, which
            leaves you with three choices: either you can go by yourself, park
            your car, and then pay the fees for it. Or get the London black cab,
            or get a service like Southend City Transfers UK. We’d suggest the
            last one, as it is the most hassle-free, charges very reasonably,
            and gets you to Heathrow in time to catch your flight, whether it’s
            a domestic flight or an international flight. These online services
            usually have the best cabs, and the best drivers and are extremely
            efficient and punctual, and pose as the best option for transfers at
            Heathrow Airport.
          </p>
          <span className="  text-[24px] md:text-[30px]">Making Decision</span>
          <p className=" ">
            In conclusion, you should get a cab service that promises both
            punctuality and comfort, without compromising on the cost of the
            ride. A perfect experience for yourself getting to the airport is
            all that matters, and Southend City Transfers gets you just that.
          </p>
        </div>
      </div>
      <Bookme />
      <PopularServices />
    </div>
  );
}

export default Services