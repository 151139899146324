import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { useAppContext } from "context/app-context";
const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef();
  const popoverDropdownRef = React.useRef();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const [auth, setAuth] = useState(appContext?.state?.auth);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleLogout = async () => {
    localStorage.clear();
    await appContext.dispatch({
      type: appContext.actions.Logout,
    });
    navigate("/login");
  };

  React.useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-dark bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <div
              title="kashif"
              className="text-dark p-3 text-center inline-flex items-center justify-center w-12 h-12"
            >
              <span className="block py-2 pl-3 pr-4 hover:text-primary  rounded md:bg-transparent md:p-0  md:dark:text-blue-500 uppercase">
                <FaRegUser />
              </span>
            </div>
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <p className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
          kashif
        </p>
        <Link
          to="/account/settings"
          onClick={closeDropdownPopover}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          My account
        </Link>
        <Link
          to="/booking/list"
          onClick={closeDropdownPopover}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          Booking List
        </Link>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <button
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt mr-2 text-sm "></i> Logout
        </button>
      </div>
    </>
  );
};

export default UserDropdown;
