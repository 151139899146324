import React from "react";

import Hero from "../components/Hero/Hero";
import PopularServices from "../components/Popular/PopularServices";
import DifferentServices from "../components/differentServices/DifferentServices";
import ChooseUs from "../components/chooseUs/ChooseUs";
import Faq from "../components/Faq/FAQ";
import airportImg from '../assets/img/southend-airport-service.png'
import ContactSupport from "../components/contactSupport/ContactSupport";

import safeImg from "../assets/img/southend-safe.png";
import pocketImg from "../assets/img/southend-pocket-friendly.png";
import luxuriousImg from "../assets/img/southend-luxurious.png";
import driverImg from "../assets/img/southend-drivers.png";
import costEffectiveImg from "../assets/img/southend-cost-effective.png";
import comfortImg from "../assets/img/southend-comfort.png";
import { Link } from "react-router-dom";


const data = [
  {
    title: "SOUTHEND CITY AIRPORT TRANSFERS",
    subTitle: "MOST RELIABLE AIRPORT PICKUP SERVICE IN LONDON",
    img: airportImg,
    text: [
      "It's always challenging when you go from one city to another on a regular basis. Long lineups at airports or traveling from your home to a taxi stand is an anxious experience. That's where Southend City airport taxi transfers come in to save the day. You can arrange your trip with our Heathrow airport taxi service to Marriott Hotel in London or any other hotel or your home before you arrive at the airport so you will not have to wait for your pickup.",
    ],
    features: [
      "Hassle-Free Airport Transfers",
      "Stansted Port Transfers",
      "Gatwick Airport Transfers",
      "Luton Airport Minicab Service",
      "London city airport transfers",
      "Heathrow Airport Transfers",
    ],
  },
];
const faqData = [
  {
    ques: "How do I book an airport transfer with Southend City Transfers?",
    servicse: [],
    ans: "Booking with us is straightforward; simply go to our website and fill out the online form, or contact our customer service team, who will help you with the reservation process.",
  },
  {
    ques: "How far in advance should I make my airport transfer reservation?",
    servicse: [],
    ans: "It is best to reserve your airport transfer as soon as your travel arrangements are finalized. This allows us to assign the best vehicle and driver for your trip.",
  },
  {
    ques: "Which Airports Does Southend City Transfers Operate From?",
    servicse: [
      "London Heathrow Airport",
      "London Gatwick Airport",
      "London Stansted Airport",
      "London Luton Airport",
      "London Southend Airport",
      "London City Airport",
    ],
    ans: "We offer our services to and from the following airports in London:",
  },
];


const chooseusData = [
  {
    img: luxuriousImg,
    title: "Pocket Friendly",
    description:
      "We believe that airport transfers should be reasonably priced without sacrificing quality. Our services are cost-effective, giving you the best value for your money.",
  },
  {
    img: pocketImg,
    title: "Luxurious",
    description:
      "With our luxury airport pickup service, you can travel in style and comfort. We make certain that our vehicles are well-maintained, clean, and equipped with modern comforts, ensuring that your ride is enjoyable.",
  },
  {
    img: comfortImg,
    title: "Safe and Secure",
    description:
      "Our first goal is your safety. Our drivers are skilled and fully licensed, ensuring a safe and secure airport pickup experience for you. To ensure your safety, we adhere to all relevant safety regulations.",
  },
  {
    img: safeImg,
    title: "Comfort and Convenience",
    description:
      "We realize the significance of a comfortable and convenient journey. Our professional chauffeurs will pick you up from your home or office and carry you to the airport, assuring a smooth and enjoyable travel.",
  },
  {
    img: costEffectiveImg,
    title: "Professional and Reliable Drivers",
    description:
      "Our team of drivers is dedicated to providing you with a professional and reliable service. They are experienced, knowledgeable about the local area, and committed to ensuring your journey is pleasant and punctual.",
  },
  {
    img: driverImg,
    title: "Cost-effective",
    description:
      "When compared to alternative modes of transportation, such as taxis or public transit, our airport transfer services are the most cost-effective option. Choose our dependable services to save time and avoid unneeded worry.",
  },
];



const AirportService = () => {
  return (
    <>
      <Hero heading="Airport Transfers" />
      <DifferentServices data={data} type="airport" />
      <ChooseUs
        data={chooseusData}
        subTitle="WHY CHOOSE"
        title="SOUTHEND CITY TRANSFER FOR AIRPORT PICKUP SERVICES?"
      />
      <Faq data={faqData} title="AIRPORT TRANSFERS SERVICE" />
      <ContactSupport />
      <div className="mt-20">
        <PopularServices />
      </div>
      <div className="mt-10 mb-10 flex justify-center">
        <Link to="/" className="uppercase bg-gradient-to-r from-[#002041] to-[#004080] text-white px-6 py-2 font-semibold">Book Now</Link>
      </div>
    </>
  );
};

export default AirportService;
