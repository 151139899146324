import React from "react";
import { useEffect } from "react";

const BookingHeader = ({
  pickup_location,
  distance,
  duration,
  dropoff_location,
}) => {
  useEffect(() => {
    if (!pickup_location || !distance || !duration || !dropoff_location) {
      return () => {};
    }
  }, [pickup_location, distance, duration, dropoff_location]);
  return (
    <div className="bg-white rounded-5 border p-2 mb-3 w-full md:w-4/5 ">
      <div className="flex flex-wrap items-center flex-col md:flex-row justify-between">
        <div className="lg:w-4/12 md:w-full sm:w-full xs:w-full">
          <div className="bg-red-300 flex items-center rounded-lg p-2 text-black">
            <span className="p-2 bg-danger rounded-lg text-black me-2">
              <svg
                className="h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M14.65 1.49C14.18 1.18 13.59 1 13 1s-1.18.18-1.65.49L2.8 6.35C2.3 6.71 2 7.26 2 7.86v9.29c0 .79.65 1.43 1.43 1.43.19 0 .37-.04.55-.12l4.92-1.64L13 15.8l4.57 1.43c.36.12.73.09 1.07-.09.38-.21.7-.56.91-.97l3.96-7.86c.28-.55.34-1.2.17-1.81-.17-.62-.54-1.18-1.05-1.59l-11-9.86zm-.31 2.53L17.3 7.74 14 9.76v-2.74zM4 17.15V8.76L11.85 4l6.46 5.82-7.85 2.61-6.46-5.82-1.69 3.34 11 9.85c.1.09.17.21.22.34l-3.38-1.06V7.74L4 5.71v11.44zM6 7.71L13.5 4.5l5.58 5.02-5.42 1.8L6 7.71z" />
              </svg>
            </span>
            {pickup_location}
          </div>
        </div>
        <div className="lg:w-4/12 md:w-full sm:w-full xs:w-full text-center">
          Approximately
          <hr className="my-1" />
          {distance} miles - {duration}
        </div>
        <div className="lg:w-4/12 md:w-full sm:w-full xs:w-full">
          <div className="bg-green-300 flex items-center rounded-lg p-2 text-black">
            <span className="p-2 bg-success rounded-lg text-black me-2">
              <svg
                className="h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.32 1.38L8 0 5 3v3H3a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2h-2V3l-3-3-1.32 1.38L10 0l-.68 1.38zM12 17H8v-2h4v2zM5 10h10v1H5v-1zm0 3h10v1H5v-1z" />
              </svg>
            </span>
            {dropoff_location.map((location, index) => (
              <React.Fragment key={index}>
                {location}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingHeader;
