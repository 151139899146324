import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

let persist = {};
if (localStorage.getItem("persist") == "undefined") {
  localStorage.removeItem("persist");
} else if (localStorage.getItem("persist") !== null) {
  persist = JSON.parse(localStorage.getItem("persist"));
}

const defaultAppState = {
  auth: persist?.auth || false,
  token: persist?.token || "",
  loading: false,
  user: persist?.user || {},
  booking: persist?.booking || {
    pickup_lat_lon: persist?.booking?.pickup_lat_lon || "",
    dropoff_lat_lon: persist?.booking?.dropoff_lat_lon || [],
    pickup_location: persist?.booking?.pickup_location || "",
    dropoff_location: persist?.booking?.dropoff_location || [],
    pickup_date: persist?.booking?.pickup_date || "",
    pickup_hour: persist?.booking?.pickup_hour || "",
    pickup_minute: persist?.booking?.pickup_minute || "",
    package: persist?.booking?.package || "",
    rdJourneyCategory: persist?.booking?.rdJourneyCategory || "",
    duration: persist?.booking?.duration || "",
    distance: persist?.booking?.distance || "",
    fare: persist?.booking?.fare || "",
    isReturn: persist?.booking?.isReturn || false,
    return_pickup_date: persist?.booking?.return_pickup_date || "",
    return_pickup_hour: persist?.booking?.return_pickup_hour || "",
    return_pickup_minute: persist?.booking?.return_pickup_minute || "",
    pickup_postal_code: persist?.booking?.pickup_postal_code || "",
    dropoff_postal_code: persist?.booking?.dropoff_postal_code || [],
  },
};

function appReducer(state, action) {
  switch (action.type) {
    case "update": {
      const updatedState = {
        ...state,
        ...action.data,
      };
      return updatedState;
    }
    case "set": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "logout": {
      return { ...defaultAppState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const actions = {
  UPDATE: "update",
  SET: "set",
  Logout: "logout",
};

// Create a provider component
const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(appReducer, {
    ...defaultAppState,
  });
  const value = { state, dispatch, actions };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Create a custom hook to use the context
const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext };
