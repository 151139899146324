import React, { useState } from "react";
import paypal from "../assets/img/paypal.svg";
import { useAppContext } from "context/app-context";
import { useEffect } from "react";
import http from "config/axios";
import Loader from "components/Shared/Loader";
import { useNavigate } from "react-router-dom";

const PaymentStripe = () => {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const [booking, setBooking] = useState({
    pickup_location: appContext?.state?.booking?.pickup_location,
    dropoff_location: appContext?.state?.booking?.dropoff_location,
    pickup_date: "",
    fare: appContext?.state?.booking?.fare,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (appContext && Object.keys(appContext.state.booking).length === 0) {
      navigate('/');
    }
  }, [appContext?.state?.booking]);

  useEffect(() => {
    const formattedDate = formateDate(appContext?.state?.booking?.pickup_date);
    setBooking({
      ...booking,
      pickup_date: formattedDate,
    });
  }, [appContext?.state?.booking?.pickup_date]);

  const formateDate = (param) => {
    const date = new Date(param);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  }


  const paypalPayment = async () => {
    setLoading(true);
    await http
      .post("/stripe", appContext.state.booking)
      .then((response) => {
        setLoading(false);
        if (response.data.approval_url) {
          window.location.href = response.data.approval_url;
        } else {
          navigate('/not-found/');
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <div className="w-full flex flex-col items-center mt-20 mb-20">
      <div className=" w-full md:w-4/5 flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-2xl ">Booking Details</p>
          <div className="mt-3 text-md">
            <span className="font-bold">Pickup Date:</span>{" "}
            {booking?.pickup_date}
          </div>
          <div className="mt-3 text-md">
            <span className="font-bold">From:</span> {booking?.pickup_location}
          </div>
          <div className="mt-3 text-md">
            <span className="font-bold">To:</span> {booking?.dropoff_location}
          </div>
          {
            appContext?.state?.booking?.isReturn && <>
              <div className="mt-10 text-md">
                <p className="text-2xl mb-3">Return Journey</p>
                <span className="font-bold">Return Date:</span>{" "}
                {formateDate(appContext?.state?.booking?.return_pickup_date)}
              </div>
              <div className="mt-3 text-md">
                <span className="font-bold">From:</span> {booking?.dropoff_location}
              </div>
              <div className="mt-3 text-md">
                <span className="font-bold">To:</span> {booking?.pickup_location}
              </div>
            </>
          }

          <div className="text-3xl text-center mt-10">
            <span className="font-bold">FARE: {booking?.fare} GBP</span>
          </div>
        </div>
        <button
          className="bg-yellow-500 mt-4 md:mt-0 hover:bg-yellow-600 text-dark font-bold py-2 px-20 rounded flex flex-row items-center"
          onClick={paypalPayment}
          disabled={loading}
        >
          {<p className="text-sm pl-3 ">Card Payment</p>}
        </button>
      </div>
      {loading && (
        <div className=" bg-[rgba(0,0,0,0.5)] w-full h-full absolute top-0 left-0">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default PaymentStripe;
