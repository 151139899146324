/* eslint-disable react/prop-types */
import React from 'react';
const MainHeading = ({ data, page }) => {
  return (
    <div
      className={`flex flex-col items-center w-full gap-1 md:gap-0 ${
        page === "contact" ? "md:max-w-[50%]" : "md:max-w-[50%]"
      }  mx-auto`}
    >
      <h5 className=" uppercase text-[16px] md:text-xl text-center font-bold tracking-wider text-primary">
        {data[0].title}
      </h5>
      <h3
        className={`text-[20px] md:text-3xl text-center font-bold md:leading-10 tracking-wider ${
          page === "contact" ? " " : ""
        } `}
      >
        {data[0].subTitle}
      </h3>
    </div>
  );
};

export default MainHeading;
