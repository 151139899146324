import React from 'react';
import featureImg from '../../assets/img/UK-airport-and-city-transfers.png';

const Feature = () => {
  return (
    <div className=" w-full mt-32 mb-32">
      <div className=" w-full flex flex-col items-center">
        <h5 className=" lg:text-xl text-[14px] font-bold uppercase tracking-wider text-primary">
          WELCOME TO
        </h5>
        <h3 className=" lg:text-3xl text-[20px] font-bold">
          UK AIRPORT AND CITY TRANSFERS
        </h3>
      </div>
      <div className=" w-full mt-7 flex justify-center ">
        <div className=" lg:max-w-[1100px] ml-4 mr-4 lg:m-0 gap-[30px] lg:gap-[0px] mx-auto flex md:flex-row flex-col items-center lg:justify-between relative">
          {/* <div className=" hidden lg:block w-[400px] h-[500px] border-[3px] border-[#000]"></div> */}
          <div className="  z-10 w-[100%] lg:w-[50%]">
            <img src={featureImg} alt="" className=" w-full lg:w-[74%]" />
          </div>
          <div className=" w-[100%] lg:w-[50%] flex flex-col gap-[40px]">
            <p>
              When you travel with Southend City, you don’t just get into a car
              and ride off into the distance. You get the luxury treatment. Each
              of our cabs is well-appointed, and staffed with the best and most
              courteous drivers.
            </p>
            <p>
              From and to any point in the UK, Southend City airport pickup cabs
              will ensure that your ride matches your expectations of a high-end
              cab service that doesn’t take a chunk out of your wallet.
            </p>
            <p>
              Yes, Southend City combines both: luxury and affordability. And
              don’t forget comfort and convenience, because these are the words
              we live by. We ensure you the best London airport transfers to
              your home or hotel.
            </p>
            <div>
              <div className=" flex gap-[15px] mb-[10px]">
                <div className=" flex flex-row gap-[15px] items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="29px"
                      height="29px"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M -0.5,14.5 C -0.5,14.1667 -0.5,13.8333 -0.5,13.5C 2.50156,1.33248 9.83489,-2.33419 21.5,2.5C 21.0004,3.88053 20.0004,4.5472 18.5,4.5C 10.3059,2.59806 5.30588,5.76473 3.5,14C 6.32019,23.7143 12.1535,26.2143 21,21.5C 22.6398,19.421 23.8064,17.0876 24.5,14.5C 25.325,13.6143 26.325,13.281 27.5,13.5C 25.8818,26.3188 18.8818,30.4854 6.5,26C 2.45582,23.0571 0.12249,19.2238 -0.5,14.5 Z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M 25.5,3.5 C 27.2726,4.31431 27.7726,5.64764 27,7.5C 23.5349,11.9668 19.7016,16.1334 15.5,20C 12.5638,19.1069 10.0638,17.2736 8,14.5C 7.3987,13.0347 7.8987,12.0347 9.5,11.5C 11.1487,12.6477 12.6487,13.981 14,15.5C 18.037,11.631 21.8704,7.63104 25.5,3.5 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Budget friendly</span>
                </div>
                <div className=" flex flex-row gap-[10px] items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="29px"
                      height="29px"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M -0.5,14.5 C -0.5,14.1667 -0.5,13.8333 -0.5,13.5C 2.50156,1.33248 9.83489,-2.33419 21.5,2.5C 21.0004,3.88053 20.0004,4.5472 18.5,4.5C 10.3059,2.59806 5.30588,5.76473 3.5,14C 6.32019,23.7143 12.1535,26.2143 21,21.5C 22.6398,19.421 23.8064,17.0876 24.5,14.5C 25.325,13.6143 26.325,13.281 27.5,13.5C 25.8818,26.3188 18.8818,30.4854 6.5,26C 2.45582,23.0571 0.12249,19.2238 -0.5,14.5 Z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M 25.5,3.5 C 27.2726,4.31431 27.7726,5.64764 27,7.5C 23.5349,11.9668 19.7016,16.1334 15.5,20C 12.5638,19.1069 10.0638,17.2736 8,14.5C 7.3987,13.0347 7.8987,12.0347 9.5,11.5C 11.1487,12.6477 12.6487,13.981 14,15.5C 18.037,11.631 21.8704,7.63104 25.5,3.5 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Availability</span>
                </div>
              </div>
              <div className=" flex gap-[15px]">
                <div className=" flex flex-row gap-[10px] items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="29px"
                      height="29px"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M -0.5,14.5 C -0.5,14.1667 -0.5,13.8333 -0.5,13.5C 2.50156,1.33248 9.83489,-2.33419 21.5,2.5C 21.0004,3.88053 20.0004,4.5472 18.5,4.5C 10.3059,2.59806 5.30588,5.76473 3.5,14C 6.32019,23.7143 12.1535,26.2143 21,21.5C 22.6398,19.421 23.8064,17.0876 24.5,14.5C 25.325,13.6143 26.325,13.281 27.5,13.5C 25.8818,26.3188 18.8818,30.4854 6.5,26C 2.45582,23.0571 0.12249,19.2238 -0.5,14.5 Z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M 25.5,3.5 C 27.2726,4.31431 27.7726,5.64764 27,7.5C 23.5349,11.9668 19.7016,16.1334 15.5,20C 12.5638,19.1069 10.0638,17.2736 8,14.5C 7.3987,13.0347 7.8987,12.0347 9.5,11.5C 11.1487,12.6477 12.6487,13.981 14,15.5C 18.037,11.631 21.8704,7.63104 25.5,3.5 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Time sensitivity</span>
                </div>
                <div className=" flex flex-row gap-[10px] items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="29px"
                      height="29px"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M -0.5,14.5 C -0.5,14.1667 -0.5,13.8333 -0.5,13.5C 2.50156,1.33248 9.83489,-2.33419 21.5,2.5C 21.0004,3.88053 20.0004,4.5472 18.5,4.5C 10.3059,2.59806 5.30588,5.76473 3.5,14C 6.32019,23.7143 12.1535,26.2143 21,21.5C 22.6398,19.421 23.8064,17.0876 24.5,14.5C 25.325,13.6143 26.325,13.281 27.5,13.5C 25.8818,26.3188 18.8818,30.4854 6.5,26C 2.45582,23.0571 0.12249,19.2238 -0.5,14.5 Z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fdcb11"
                          d="M 25.5,3.5 C 27.2726,4.31431 27.7726,5.64764 27,7.5C 23.5349,11.9668 19.7016,16.1334 15.5,20C 12.5638,19.1069 10.0638,17.2736 8,14.5C 7.3987,13.0347 7.8987,12.0347 9.5,11.5C 11.1487,12.6477 12.6487,13.981 14,15.5C 18.037,11.631 21.8704,7.63104 25.5,3.5 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Flexibility</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
