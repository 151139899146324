import React, { useEffect, useState } from "react";
import Joi from "joi";
import http from "../config/axios";

/* toast alert import*/
import toast from "react-hot-toast";
import { useAppContext } from "context/app-context";

/** fields validator */
const schema = Joi.object({
  full_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages({ "string.empty": "Firstname is required" }),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
  phone: Joi.string()
    .pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Please Provide Valid Phone Number",
    }),
  profileImage: Joi.optional(),
});

const passwordSchema = Joi.object({
  old_password: Joi.string()
    .required()
    .messages({ "string.empty": "Current Password is required" }),
  new_password: Joi.string().min(8).required().messages({
    "string.empty": "New Password is required",
    "string.min": "Password must be minimum 8 characters long",
  }),
  confirm_password: Joi.string()
    .min(8)
    .valid(Joi.ref("new_password"))
    .required()
    .messages({
      "string.empty": "Confirm Password is required",
      "any.only": "Password mismatch ",
    }),
});

export default function AccountSettings() {
  const [openTab, setOpenTab] = useState(1);
  const appContext = useAppContext();
  const [authToken, setAuthToken] = useState(
    "Bearer " + appContext?.state?.token
  );
  const [authUser, setAuthUser] = useState(JSON.parse(appContext?.state?.user));
  const [user, setUser] = useState({
    full_name: authUser.name,
    email: authUser.email,
    phone: authUser.contact,
    profileImage: authUser.profile_image,
  });
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [fileDataURL, setFileDataURL] = useState(null);
  const [file, setFile] = useState(null);
  const [clientError, setClientError] = useState(null);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);

      const formData = new FormData();
      formData.append("newProfileImage", file);
      formData.append("name", user.full_name);
      formData.append("contact", user.phone);
      formData.append("profile_image", user.profileImage);

      http
        .post("profileUpdate", formData, {
          headers: {
            authorization: authToken,
          },
        })
        .then((res) => {
          toast.success(res.data.success);
          localStorage.setItem("user", JSON.stringify(res.data.user));
        })
        .catch((err) => {
          toast.error(err.response.data.error);
        });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let validator = passwordSchema.validate({ ...formData });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);
      const newFormData = new FormData();
      newFormData.append("old_password", formData.old_password);
      newFormData.append("new_password", formData.new_password);
      newFormData.append("confirm_password", formData.confirm_password);
      // Perform api call here
      http
        .post("changePassword", formData, {
          headers: {
            authorization: authToken,
          },
        })
        .then((response) => {
          setFormData({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
          toast.success(response.data.success);
        })
        .catch((err) => toast.error(err.response.data.error));
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-wordsw-full mb-6 shadow-lg rounded-lg">
          <div className="w-full px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="px-4 relative w-full md:w-12/12">
                <div className="relative py-12">
                  <section className="text-center block">
                    <div className="text-center">
                      <h3 className="text-3xl font-semibold mt-2 mb-4">
                        My Account
                      </h3>
                      <div></div>
                    </div>
                  </section>
                  <section className="block">
                    <ul
                      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                      role="tablist"
                    >
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 1
                              ? "text-white bg-zinc-950"
                              : "text-lightBlue-600 bg-zinc-400")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-user text-base mr-1"></i> Profile
                        </a>
                      </li>

                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 2
                              ? "text-white bg-zinc-950"
                              : "text-lightBlue-600 bg-zinc-400")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-user text-base mr-1"></i> Change
                          Password
                        </a>
                      </li>
                    </ul>
                  </section>
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="ml-auto px-4 relative w-full md:w-12/12 justify-center">
                {openTab === 1 && (
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <form onSubmit={formSubmit}>
                        <div className="container mx-auto px-4">
                          <div className="flex justify-between">
                            <h3 className="text-3xl font-semibold mt-4 mb-6">
                              Personal Information
                            </h3>
                          </div>
                          <div className="flex flex-wrap -mx-4">
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                First name*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. Smith"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  onChange={handleInputChange}
                                  value={user?.full_name}
                                  name="full_name"
                                />
                                {clientError &&
                                  clientError?.context?.key === "full_name" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Email address*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. email@email.email"
                                  type="email"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  value={user?.email}
                                  readOnly
                                />
                                {clientError &&
                                  clientError?.context?.key === "email" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Phone*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. +1 (5417) 543 010"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  value={
                                    user?.phone === "null" ? "" : user?.phone
                                  }
                                  onChange={handleInputChange}
                                  name="phone"
                                />
                                {clientError &&
                                  clientError?.context?.key === "phone" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Profile*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  type="file"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) => setFile(e.target.files[0])}
                                />
                              </div>
                            </div>

                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              {fileDataURL ? (
                                <p className="img-preview-wrapper">
                                  {
                                    <div className="mt-4">
                                      <img
                                        src={fileDataURL}
                                        alt="preview"
                                        height="100"
                                        width="100"
                                      />
                                    </div>
                                  }
                                </p>
                              ) : (
                                <div className="mt-4">
                                  <img
                                    src={
                                      user?.profileImage &&
                                      user?.profileImage !== "null"
                                        ? "https://southendcity.marketmediaconnect.com/backend/public/storage/" +
                                          user?.profileImage
                                        : "http://127.0.0.1:8000" +
                                          "/uploads/profile/avatar.jpg"
                                    }
                                    alt=""
                                    height="100"
                                    width="100"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="flex justify-between mt-12 mb-8">
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md bg-zinc-950">
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {openTab === 2 && (
                  <div className="relative flex flex-col min-w-0 break-words bg-white mb-6 shadow-lg rounded-lg w-6/12">
                    <div className="px-4 py-5 flex-auto">
                      <form
                        className="myForm"
                        autoComplete="off"
                        onSubmit={onSubmit}
                      >
                        <div className="container mx-auto px-4">
                          <div className="flex justify-between">
                            <h3 className="text-3xl font-semibold mt-4 mb-6">
                              Change Password
                            </h3>
                          </div>
                          <div className="flex flex-wrap -mx-4">
                            <div className="px-4 pb-2 relative w-full">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Current Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Enter your current password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="old_password"
                                  onChange={handlePasswordChange}
                                  value={formData.old_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                    "old_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-12/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                New Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Enter new password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="new_password"
                                  onChange={handlePasswordChange}
                                  value={formData.new_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                    "new_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-12/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Confirm Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Confirm Password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="confirm_password"
                                  onChange={handlePasswordChange}
                                  value={formData.confirm_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                    "confirm_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between mt-12 mb-8">
                            <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-zinc-950 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                              Update Password
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
