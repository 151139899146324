import React from "react";

import Hero from "../components/Hero/Hero";
import PopularServices from "../components/Popular/PopularServices";
import DifferentServices from "../components/differentServices/DifferentServices";
import ChooseUs from "../components/chooseUs/ChooseUs";
import Faq from "../components/Faq/FAQ";
import ContactSupport from "components/contactSupport/ContactSupport";
import cruisImg from '../assets/img/southend-cruise-service.png'
import safeImg from "../assets/img/southend-safe.png";
import pocketImg from "../assets/img/southend-pocket-friendly.png";
import luxuriousImg from "../assets/img/southend-luxurious.png";
import driverImg from "../assets/img/southend-drivers.png";
import costEffectiveImg from "../assets/img/southend-cost-effective.png";
import comfortImg from "../assets/img/southend-comfort.png";
import { Link } from "react-router-dom";

const chooseusData = [
  {
    img: luxuriousImg,
    title: "Pickup at the airport",
    description:
      "Our driver will meet you at the airport terminal to make things easy for you. Simply walk to the waiting area to meet your driver after you have collected your luggage. We value you and do not want you to be inconvenienced, so we make transportation from the airport to the hotel convenient for you.",
  },
  {
    img: pocketImg,
    title: "Cashless Payment",
    description:
      "We understand that not everyone brings cash with them. As a result, our airport transfer service accepts cashless payments. At the end of your ride, the credit card associated with your account will be charged.",
  },
  {
    img: comfortImg,
    title: "Flight Tracking",
    description:
      "Our first goal is your safety. Our drivers are skilled and fully licensed, ensuring a safe and secure airport pickup experience for you. To ensure your safety, we adhere to all relevant safety regulations.",
  },
  {
    img: safeImg,
    title: "Private and Personalised Service",
    description:
      "Our hotel transfer services are private, you will have the entire car to yourself or your company. This provides for a more personalized experience; we ensure that you have a comfortable vehicle that meets your requirements.",
  },
  {
    img: costEffectiveImg,
    title: "PVariety of Vehicles to Choose From",
    description:
      "We provide a large selection of vehicles to meet all of your needs. Whether you require a premium Mercedes for a business meeting or a minivan for a group, we have you covered.",
  },
  {
    img: driverImg,
    title: "Airport transfer service that is safe and convenient",
    description:
      "We provide seamless transfer services from the hotel to the airport, removing the need to navigate public transportation or haggle with taxi drivers. You don't know anything about taxi drivers, which makes you feel worried but our drivers are subjected to rigorous scrutiny, so you know you're in good hands.",
  },
];

const data = [
  {
    title: "SOUTHEND CITY CRUISE PORT TRANSFERS",
    subTitle: "MOST RELIABLE CRUISE PORT PICKUP SERVICE IN LONDON",
    img: cruisImg,
    text: [
      "London is a city of history, business, and tourism. It's also a city with many ports and airports, which can make it difficult to get around. But with Southend City Transfers, getting around London is easy and stress-free. Our airport to cruise terminal transfers can take you to any port in the city, or vice versa. We provide meet-and-greet services at all of the main seaports in London, including Southampton Cruise Port, Dover Cruise Port, London Tilbury Cruise Port, Harwich Cruise Port, and Portsmouth Cruise Port. If you're a citizen of London, our Cruise Port Transfers Services can take you to any port in the city from your home. We offer a variety of vehicles to accommodate your needs, including sedans, SUVs, minivans, and buses. Our drivers are experienced and professional, and they will make sure that you have a safe and comfortable journey.",
    ],
  },
];

const faqData = [
  {
    ques: "How do I book an airport transfer with Southend City Transfers?",
    servicse: [],
    ans: "Booking with us is straightforward; simply go to our website and fill out the online form, or contact our customer service team, who will help you with the reservation process.",
  },
  {
    ques: "How far in advance should I make my airport transfer reservation?",
    servicse: [],
    ans: "It is best to reserve your airport transfer as soon as your travel arrangements are finalized. This allows us to assign the best vehicle and driver for your trip.",
  },
  {
    ques: "Which Airports Does Southend City Transfers Operate From?",
    servicse: [
      "London Heathrow Airport",
      "London Gatwick Airport",
      "London Stansted Airport",
      "London Luton Airport",
      "London Southend Airport",
      "London City Airport",
    ],
    ans: "We offer our services to and from the following airports in London:",
  },
];

const expectationData = [
  {
    title: "Unforgettable Memories, Stress-Free Travel",
    desc: "From the time you step off the plane, your cruise experience should be packed with wonderful memories. That is why we work hard to make your trip as comfortable and trouble-free as possible. We ensure that your transport requirements are addressed with the highest care, allowing you to focus on the anticipation and excitement of your impending trip.",
  },
  {
    title: "Sit Back, Relax, and Enjoy the Ride",
    desc: "You can sit back, relax, and enjoy the ride when you choose us for your cruise port transfers. We handle the logistics, traffic navigation, and your comfort so you can fully enjoy the excitement and anticipation of your trip. Allow us to manage the transportation to ports in London so you can focus on creating memories to last a lifetime.",
  },
];

const CruiseService = () => {
  return (
    <>
      <Hero heading="Cruise Port Transfers"></Hero>

      <DifferentServices data={data} type="hotel" />
      {/* <ChooseUs data={chooseusData} /> */}
      <div className="services-wrapper py-12">
        <div
          className={` w-[90%] sm:max-w-[70%] text-center mx-auto text-white`}
        >
          <span className=" text-[30px]">
            Transportation to Cruise Terminal
          </span>
          <p className="  mt-5">
          Southend City Transfer is your reliable option for cruise terminal transportation. We recognize the importance of a safe and comfortable journey before and after your cruise, and our services are tailored to your unique requirements. Our dedication to providing great customer service distinguishes us, as we seek to exceed your expectations and give you a hassle-free transportation experience. Our transfers services provide convenient transportation from Heathrow to Southampton Cruise Terminal, ensuring a smooth journey.
          </p>
        </div>
        <div className=" mt-12 w-[90%] sm:max-w-[70%] mx-auto flex gap-8 flex-wrap">
          {expectationData.map((item,i) => (
            <div key={i} className=" bg-white p-8 flex-grow  basis-[100%] md:basis-[40%]">
              <span className="font-bold">{item.title}</span>
              <p className="mt-2">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <div className=" bg-[#f5f5f5] py-16">
        <div className=" w-full p-3 sm:max-w-[70%] mx-auto">
          <div className=" text-[20px] sm:text-[25px] md:text-[35px] font-bold mb-3">
            Book Your Cruise Port Transfer Today
          </div>
          <p className=" text-[#374151]">
          Are you looking for a hassle-free transfer from the airport to your cruise terminal? Make a reservation for your Cruise Port Transfer with us today. Simply go to our website, choose your preferred pick-up and drop-off locations, select the vehicle that best meets your needs, then provide us with your trip information. Our effective online reservation system provides a smooth reservation process.
          </p>
          <p className=" font-bold mt-[40px] md:mt-[70px]">
          So what are you waiting for? Book your transfer today and let our cruise port transfers take the stress out of getting around London.
          </p>
        </div>
      </div>
      <Faq data={faqData} page = "cruise" title="Cruise Port Transfer Services" />

      <ContactSupport />

      <div className="">
        <PopularServices />
      </div>

      <div className="mt-10 mb-10 flex justify-center">
        <Link to="/" className="uppercase bg-gradient-to-r from-[#002041] to-[#004080] text-white px-6 py-2 font-semibold">Book Now</Link>
      </div>
    </>
  );
};

export default CruiseService;
