import React from "react";
import { Route, Routes} from "react-router-dom";
// styles
import "./assets/styles/index.css";
import "./assets/styles/custom.css";

//components

import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Main from "./layouts/Main";
import Reviews from "./pages/Reviews";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Login from "./pages/Login";
import AirportService from "./pages/AirportService";
import HotelService from "./pages/HotelService";
import CruiseService from "./pages/CruiseService";
import StationService from "./pages/StationService";
import ForgotPassword from "./pages/ForgotPassword";
import Signup from "./pages/Signup";
import Booking from "./pages/Booking";
import BookingConfirm from "./pages/BookingConfirm";
import CustomerDashboard from "./pages/CustomerDashboard";
import Protected from "./middleware/Protected";
import AccountSettings from "./pages/AccountSettings";
import Vehicle from "pages/Vehicle";
import { AppProvider } from "context/app-context";
import BookingDetails from "pages/BookingDetails";
import PaymentStripe from "pages/PaymentStripe";
import BookingStripeConfirm from "pages/BookingStripeConfirm";
import BlogDetails from "pages/BlogDetails";
import ScrollToTop from "pages/ScrollToTop";
import Services from "pages/Services";
import Review from "pages/Review";
import NotFound from "pages/NotFound";
import ResetPassword from "./pages/ResetPassword";



const App = () => {


  return (
    <>
      {
        <AppProvider>
          <Main>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/about" element={<Aboutus />} exact />
              <Route path="/reviews" element={<Reviews />} exact />
              <Route path="/contact" element={<Contact />} exact />
              <Route path="/blog" element={<Blog />} exact />
              <Route path="/blog/:id" element={<BlogDetails />} exact />
              <Route path="/login" element={<Login />} exact />
              <Route path="/signup" element={<Signup />} exact />
              <Route path="/review" element={<Review />} exact />
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
                exact
              />
              <Route path="/reset-password/:token" element={<ResetPassword />} exact />
              <Route
                path="/booking/confirm"
                element={<BookingConfirm />}
                exact
              />
              <Route
                path="/booking/confirm/stripe"
                element={<BookingStripeConfirm />}
                exact
              />
              <Route path="/services" element={<Services />} />

              <Route
                path="/services/airport"
                element={<AirportService />}
                exact
              />
              <Route path="/services/hotel" element={<HotelService />} exact />
              <Route
                path="/services/cruise"
                element={<CruiseService />}
                exact
              />
              <Route
                path="/services/station"
                element={<StationService />}
                exact
              />

              <Route
                path="/booking/list"
                element={<Protected Component={CustomerDashboard} />}
                exact
              />
              <Route
                path="/account/settings"
                element={<Protected Component={AccountSettings} />}
                exact
              />
              <Route path="/vehicle/selection" element={<Vehicle />} exact />
              <Route
                path="/booking/details"
                element={<BookingDetails />}
                exact
              />
              <Route path="/payment/paypal" element={<Booking />} exact />
              <Route path="/payment/stripe" element={<PaymentStripe />} exact />
              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Main>
        </AppProvider>
      }
    </>
  );
};

export default App;
