import React, {  useState } from "react";
import phoneImg from "../../assets/img/contact-phone.png";
import emailImg from "../../assets/img/contact-email.png";
import locationImg from "../../assets/img/contact-loc.png";
import whatsappImg from "../../assets/img/whatsapp.png";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import http from "../../config/axios";
// import Loader from "components/Shared/Loader";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  //  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || subject === "" || message === "") {
      setError(true);
      return;
    }
    const data = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    http
      .post("/contactUs", data) 
      .then((res) => {
        toast.success("Thank you. You form has submitted successfully!");
      })
      .catch((err) => {
        console.error(err);
      });
    navigate("/");
  };

  // useEffect(() => {
  // const formSubmit = async () => {
  //   try {

  //   } catch (error) {

  //   }
  // }
  // },[])
  return (
    <div className=" w-full flex justify-center">
      <div className=" w-full md:w-[80%] flex flex-col lg:flex-row gap-4 lg:gap-0 mb-9 justify-between items-center p-4">
        <div className=" flex flex-col gap-10 w-full lg:w-[60%]">
          <div className=" flex items-center gap-2">
            <img src={locationImg} alt="" height={50} width={50} />
            <div>
              <h6 className=" text-sm font-bold">Location</h6>
              <p className=" text-sm">Bengarth Road Northolt Ub5 5lf</p>
            </div>
          </div>
          <div className=" flex items-center gap-2">
            <Link to="tel:+442080049002">
              <img src={phoneImg} alt="" height={50} width={50} />
            </Link>
            <div>
              <h6 className=" text-sm font-bold">Phone</h6>
              <div className=" flex gap-2">
                <Link
                  to="tel:+442080049002"
                  className=" text-sm pr-2 border-r border-gray-950"
                >
                  (+442) 0800-49002
                </Link>
                <Link
                  to="https://api.whatsapp.com/send?phone=+447511110030&text=Hi"
                  className=" flex"
                >
                  <img src={whatsappImg} alt="" />
                  <p className=" text-sm">(+442) 0800-49002</p>
                </Link>
              </div>
            </div>
          </div>

          <Link
            to="mailto:booking@southendcitytransfer.co.uk"
            className=" flex items-center gap-2"
          >
            <img src={emailImg} alt="" height={50} width={50} />
            <div>
              <h6 className=" text-sm font-bold">Email</h6>
              <p className=" mailto:text-sm">
                booking@southendcitytransfer.co.uk
              </p>
            </div>
          </Link>
        </div>
        <div className=" w-full lg:w-[45%]">
          <form
            className=" w-full flex flex-wrap p-4 shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className=" flex  flex-col w-full md:w-[46%] mb-6 mr-4">
              <label htmlFor="name" className=" text-[16px] font-bold">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
              />
              {error && name === "" && (
                <span className=" text-red-600 mt-1 font-bold">
                  Name is required!
                </span>
              )}
            </div>
            <div className=" flex  flex-col w-full md:w-[46%] mb-6 md:ml-3">
              <label htmlFor="email" className=" text-[16px] font-bold">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
              />
              {error && email === "" && (
                <span className=" text-red-600 mt-1 font-bold">
                  Email is required!
                </span>
              )}
            </div>
            <div className=" flex w-full  flex-col mb-6">
              <label htmlFor="subject" className=" text-[16px] font-bold">
                Subject
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                onChange={(e) => setSubject(e.target.value)}
                className=" bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
              />
              {error && subject === "" && (
                <span className=" text-red-600 mt-1 font-bold">
                  Subject is required!
                </span>
              )}
            </div>
            <div className=" flex w-full flex-col mb-6">
              <label htmlFor="message" className=" text-[16px] font-bold">
                Message
              </label>
              <textarea
                type="text"
                name="message"
                id="message"
                onChange={(e) => setMessage(e.target.value)}
                className=" bg-gray-200 h-[150px] text-gray-700 border border-gray-200 rounded px-4 py-3 leading-tight focus:outline-none focus:bg-white"
              />
              {error && message === "" && (
                <span className=" text-red-600 mt-1 font-bold">
                  Message is required!
                </span>
              )}
            </div>
            <button
              type="submit"
              className=" uppercase w-full text-white text-[16px] py-3 bg-gradient-to-r from-[#002041] to-[#000810]"
            >
              Send message
            </button>
          </form>
        </div>
      </div>
      {/* {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )} */}
    </div>
  );
};

export default ContactForm;
