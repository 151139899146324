import React from 'react';
import FaqItem from "./FaqItem";

const Faq = ({data,title, page}) => {
  return (
    <div
      className={`w-full relative flex items-center flex-col ${
        page === "cruise" ? "mt-0" : "mt-24"
      } p-3 sm:p-6 md:p-8 bg-[url('../../assets/img/southend-faqs.png')] object-cover`}
    >
      <div className=" w-full h-full absolute top-0 left-0 bg-[#002041cc]"></div>
      <div className=" flex flex-col items-center w-full gap-1 md:gap-0 lg:max-w-[50%] z-[999]">
        <h5 className=" uppercase text-[18px] md:text-xl text-center font-bold tracking-wider text-white">
          {title}
        </h5>
        <h3 className=" uppercase text-white text-2xl md:text-3xl text-center font-bold leading-8 md:leading-10 tracking-wider">
          FREQUENTLY ASKED QUESTIONS
        </h3>
      </div>
      <div className=" w-[100%] md:w-[60%] mt-6 z-[9999] ">
        {data &&
          data.map((item, i) => (
            <div key={i}>
              <FaqItem item={item} index={i} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Faq;
