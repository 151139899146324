import React from "react";

import aboutus from "../assets/img/southend-aboutus.png";
import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "components/pageHeading/PageHeading";
import AboutFeatures from "components/aboutFeatures/AboutFeatures";
import UniqueService from "components/uniqueServices/UniqueServices";
import Bookme from "components/bookMe/BookMe";

const Aboutus = () => {
  return (
    <>
      <PageHeading title="About us" img = {aboutus} />
      <AboutFeatures />
      <UniqueService />
      <div className="mt-20">
        <PopularServices />
      </div>
      <Bookme />
     
    </>
  );
};

export default Aboutus;
