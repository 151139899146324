import React from 'react';
import ChooseUsItem from "./ChooseUsItem";


const ChooseUs = ({ data, subTitle , title}) => {
  return (
    <div className="w-full flex items-center flex-col mt-28">
      <div className=" flex flex-col items-center w-full gap-1 md:gap-0 lg:max-w-[50%]">
        <h5 className=" uppercase text-[18px] md:text-xl text-center font-bold tracking-wider text-[rgb(0,64,128)]">
          {subTitle}
        </h5>
        <h3 className=" uppercase text-2xl md:text-3xl text-center font-bold leading-8 md:leading-10 tracking-wider">
          {title}
        </h3>
      </div>
      <div className=" w-[80%] md:w-[60%] flex flex-wrap justify-between mt-9">
        {data.map((item, i) => (
          <ChooseUsItem
            item={item}
            right={i % 2 !== 0 ? true : false}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default ChooseUs;
