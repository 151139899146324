import React from 'react'

const BlogDetailsTitle = ({ title, createdAt }) => {

 

  return (
    <div className=" w-full bg-[#f1f5f9] flex justify-center">
      <div className=" max-w-[1000px] p-6 text-center">
        <h1 className=" text-[26px] sm:text-[30px] md:text-[40px] font-bold text-[#4b5563]">
          {title}
        </h1>
        <span>Published August 03, 2023</span>
      </div>
    </div>
  );
};

export default BlogDetailsTitle