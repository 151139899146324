/* eslint-disable react/prop-types */
import React from 'react';


// eslint-disable-next-line no-unused-vars
const ChooseUsItem = ({ item, right }) => {
  return (
    <div
      className={` w-full md:w-[48%] flex flex-col gap-6   ${
        right ? " md:flex-col-reverse " : ""
      } gap-4`}
    >
      <div className=" w-full">
        <img src={item.img} alt="" />
      </div>
      <div>
        <h5 className=" font-bold">{item.title}</h5>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

export default ChooseUsItem;
