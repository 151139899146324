import http from "config/axios";
import { useAppContext } from "context/app-context";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const BookingConfirm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paypalPaymentId = queryParams.get("paypal_payment_id");
  const appContext = useAppContext();

  const [booking, setBooking] = useState(appContext?.state?.booking);

  useEffect(() => {
    if (paypalPaymentId) {
      setBooking((prevBooking) => ({
        ...prevBooking,
        payment_id: paypalPaymentId,
      }));
    }
  }, [paypalPaymentId]);

  useEffect(() => {
    async function storeBooking() {
      try {
        if (paypalPaymentId && booking.payment_id === paypalPaymentId) {
          const response = await http.post("/createBooking", booking);
          if (response.data.success === true) {
            toast.success(response.data.message);
          }else{
            toast.error(response.data.message);
          }
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
      } catch (error) {
        console.log(error.response.data);
      }
    }

    storeBooking(); // Call the function directly within useEffect
  }, [booking, paypalPaymentId]);
  return  <div className="min-h-screen flex items-center justify-center">
  <div className="bg-white p-8 shadow-md rounded-md" style={{ minHeight: "300px", margin: "20px" }}>
      <h1 className="text-2xl font-semibold mb-6">Booking Confirmed</h1>
      <p className="text-gray-600 mb-4">
        Thank you for your booking! Your reservation has been confirmed.
      </p>
      <p className="text-gray-600">
        Payment ID: {booking?.payment_id || "N/A"}
      </p>
      <button
        onClick={() => {
          window.location.href = "/"; // Redirect to the home page
        }}
        style={{
          backgroundColor: "#3490dc", // Replace with your desired color
          hover: { backgroundColor: "#2779bd" }, // Replace with your desired hover color
          color: "#fff",
          fontWeight: "bold",
          padding: "0.5rem 1rem",
          marginTop: "1rem",
          borderRadius: "0.25rem",
          cursor: "pointer",
        }}
      >
        Back to Home
      </button>
    </div>
  </div>
};

export default BookingConfirm;
