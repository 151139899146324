import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import http from "../../config/axios";
import { useAppContext } from "context/app-context";
import { useNavigate } from "react-router-dom";
import Joi, { date } from "joi";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import PlacesAutocomplete from "react-places-autocomplete";
import { IoAddSharp } from "react-icons/io5";
import { IoMdRemove } from "react-icons/io";
import toast from "react-hot-toast";

/** fiedls validator */
const schema = Joi.object({
  pickup_location: Joi.string().required().messages({
    "string.empty": "Pickup Location is required",
  }),
  dropoff_location: Joi.array().required().messages({
    "any.required": "Dropoff Location is required",
  }),
  pickup_date: Joi.string().required().messages({
    "string.empty": "Pickup Date is required",
  }),
  pickup_hour: Joi.string().required().messages({
    "string.empty": "Pickup Hour is required",
  }),
  pickup_minute: Joi.string().required().messages({
    "string.empty": "Pickup Minute is required",
  }),
  pickup_lat_lon: Joi.string().optional(),
  dropoff_lat_lon: Joi.array().optional(),
  rdJourneyCategory: Joi.string().optional(),
  pickup_postal_code: Joi.string().optional(),
  dropoff_postal_code: Joi.array().optional(),
});

const Hero = ({ heading }) => {
  const [additional, setAdditional] = useState(0);
  const [activeTab, setActiveTab] = useState("transfer");
  const [formData, setFormData] = useState({
    pickup_lat_lon: "",
    dropoff_lat_lon: [],
    pickup_location: "",
    dropoff_location: [],
    pickup_date: "",
    pickup_hour: "",
    pickup_minute: "",
    rdJourneyCategory: "transfer",
    pickup_postal_code: "",
    dropoff_postal_code: [],
  });
  const [originAddress, SetOriginAddress] = useState("");
  const [originCoordinate, setOriginCoordinate] = useState({
    lat: null,
    lng: null,
  });
  const [dropOffFields, setDropOffFields] = useState([]);
  const [destinationAddresses, setDestinationAddresses] = useState([]);
  const [destinationCoordinate, setDestinationCoordiante] = useState([
    {
      lat: null,
      lng: null,
    },
  ]);

  const btnDropdownRef = useRef();

  useEffect(() => {
    const getAdditional = async () => {
      http
        .get("additionalDropoff")
        .then((res) => setAdditional(res.data.additional))
        .catch((err) => console.log(err));
    };
    getAdditional();
  }, []);

  const addDropOffField = () => {
    if (dropOffFields.length < additional) {
      const newId = dropOffFields.length + 1;
      setDropOffFields([...dropOffFields, { id: newId }]);
      setDestinationAddresses([...destinationAddresses, ""]);
      setDestinationCoordiante([
        ...destinationCoordinate,
        { lat: null, lng: null },
      ]);
    } else {
      toast.error('You have reached maximum number of additional drop off\'');
    }
  };

  // const addDropOffField = () => {
  //   if (dropOffFields.length < additional) {
  //     // Initialize new fields with empty or default values
  //     const newDropOffField = { id: dropOffFields.length + 1, value: "" }; // Assuming `value` is what you would bind to the input
  //     setDropOffFields([...dropOffFields, newDropOffField]);
  //     setDestinationAddresses([...destinationAddresses, ""]);
  //     // Ensure initial coordinates are set to avoid undefined values
  //     setDestinationCoordiante([...destinationCoordinate, { lat: null, lng: null }]);
  //   } else {
  //     toast.error("You have reached the maximum number of additional drop-offs.");
  //   }
  // };

  const removeDropOffField = (idToRemove) => {
    const updatedFields = dropOffFields.filter(
      (field, index) => index !== idToRemove - 1
    );

    const dropoffLocations = formData?.dropoff_location.filter((_, index) => index !== idToRemove);
    const dropoffLatLon = formData?.dropoff_lat_lon.filter((location, index) => index !== idToRemove);
    const dropoffPostalCode = formData?.dropoff_postal_code.filter((location, index) => index !== idToRemove);
    const updatedAddresses = destinationAddresses.filter(
      (_, index) => index !== idToRemove
    );
    const updatedCoordinates = destinationCoordinate.filter(
      (_, index) => index !== idToRemove
    );

    // destinationAddresses()
    setDropOffFields(updatedFields);
    setDestinationAddresses(updatedAddresses);
    setDestinationCoordiante(updatedCoordinates);
    setFormData({
      ...formData,
      dropoff_location: dropoffLocations,
      dropoff_lat_lon: dropoffLatLon,
      dropoff_postal_code: dropoffPostalCode
    });
  };

  const originhandleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    let postalCode = result[0].address_components.find(component => component.types.includes('postal_code'));
    console.log("postalCode", postalCode);
    if (postalCode) {
      postalCode = splitStringByTwoWords(postalCode.long_name) || splitStringByTwoWords(postalCode.short_name);
    }


    setFormData((prevFormData) => ({
      ...prevFormData,
      pickup_location: value,
      pickup_lat_lon: `${ll.lat},${ll.lng}`,
      pickup_postal_code: postalCode ? postalCode : value,
    }));


    SetOriginAddress(value);
    setOriginCoordinate(ll);
    handleDropdownSelect();
  };

  function splitStringByTwoWords(str) {
    const regex = /^(\S+)\s+(\S+)$/;
    if (regex.test(str)) {
        return str.split(/\s+/)[0];
    } else {
        return str;
    }
}

  const destinationhandleSelect = async (value, index) => {
    const result = await geocodeByAddress(value);
    let postalCode = result[0].address_components.find(component => component.types.includes('postal_code'));
    if (postalCode) {
      postalCode = splitStringByTwoWords(postalCode.long_name) || splitStringByTwoWords(postalCode.short_name);
    }else{
      postalCode = value;
    }
    const ll = await getLatLng(result[0]);

    const updatedAddresses = [...destinationAddresses];
    updatedAddresses[index] = value;

    setDestinationAddresses(updatedAddresses);

    const updatedCoordinates = [...formData.dropoff_lat_lon];
    updatedCoordinates[index] = `${ll.lat},${ll.lng}`;
    setDestinationCoordiante(updatedCoordinates);

    const updatedPostalCode = [...formData.dropoff_postal_code];
    updatedPostalCode[index] = postalCode || formData?.dropoff_location[index];

    const updatedFormData = {
      ...formData,
      dropoff_location: updatedAddresses,
      dropoff_lat_lon: updatedCoordinates,
      dropoff_postal_code: updatedPostalCode,
    };
    setFormData(updatedFormData);
    handleDropdownSelect();

  };

  const setDestinationAddressesByIndex = (value, index) => {
    const updatedAddresses = [...destinationAddresses];
    updatedAddresses[index] = value;
    setDestinationAddresses(updatedAddresses);
  };

  //
  const appContext = useAppContext();
  const navigate = useNavigate();

  const [clientError, setClientError] = useState(null);

  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let validator = schema.validate(formData);
    console.log("validator", validator);
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      try {
        setFormData(
          (prevFormData) => ({
            ...prevFormData,
          }),
          () => { }
        );
        appContext.dispatch({
          type: appContext?.actions?.UPDATE,
          data: {
            ...appContext.state,
            booking: formData,
          },
        });
        const existingDataString = localStorage.getItem("persist");
        const existingData = existingDataString
          ? JSON.parse(existingDataString)
          : {};
        existingData.booking = formData;
        localStorage.setItem("persist", JSON.stringify(existingData));
        navigate("/vehicle/selection");
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    }
  };

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem(
      "persist",
      JSON.stringify({
        auth: false,
        user: {}, // Store as an empty object directly
        token: "",
        booking: {}, // Store as an empty object directly
      })
    );

    appContext.dispatch({
      type: appContext?.actions?.UPDATE,
      data: {
        ...appContext?.state,
        booking: {},
      },
    });
    http
      .get("getLocations")
      .then((res) => { setLocations(res.data.locations); setFilteredLocations(res.data.locations) })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        handleDropdownSelect();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);

  const [showDropdown, setShowDropdown] = useState({
    pickup: false,
    dropoff: false
  });

  const handleLocationChange = (location, input, index) => {
    if (input === 'pickup') {
      setFormData({
        ...formData,
        pickup_lat_lon: `${location.latitude},${location.longitude}`,
        pickup_location: location.name,
        pickup_postal_code: location.name
      });
      SetOriginAddress(location.name);
    } else if (input === 'dropoff' || input === 'additional_dropoffs') {
      setDestinationAddresses()
      setFormData(prevFormData => {
        const newDropoffLocation = [...prevFormData.dropoff_location];
        const newDropoffLatLon = [...prevFormData.dropoff_lat_lon];

        newDropoffLocation[index] = location.name;
        newDropoffLatLon[index] = `${location.latitude},${location.longitude}`;

        setDestinationAddressesByIndex(location.name, index);

        const updatedPostalCode = [...prevFormData.dropoff_postal_code];
        updatedPostalCode[index] =  newDropoffLocation[index];

        return {
          ...prevFormData,
          dropoff_location: newDropoffLocation,
          dropoff_lat_lon: newDropoffLatLon,
          dropoff_postal_code: updatedPostalCode,
        };
      });
    }

    handleDropdownSelect();
  };

  const toggleDropdown = (input) => {
    setShowDropdown({ ...showDropdown, [input]: !showDropdown.input });
  };

  const handleDropdownSelect = () => {
    setShowDropdown(false);
  };

  return (
    <div className="h-full hero-wrapper sm:flex sm:justify-end xl:pb-24">
      <div className="hero-content ml-15  mt-0 mr-0 md:mr-24 pt-6 md:pt-20 pb-24">
        <h1 className="  text-center  uppercase text-white text-3xl ml-6 md:ml-0 md:text-4xl font-bold">
          {heading}
        </h1>
        <div className="book-form pt-6 px-2 sm:px-8 mt-4 pb-6 w-[100%]  sm:w-[100%]">
          <div className="book-form-clip"></div>
          <div className="book-form-header">
            <button
              onClick={() => {
                setActiveTab("transfer");
                setFormData({ ...formData, rdJourneyCategory: "transfer" });
              }}
              className={`px-8 py-3  w-2/4 hover:shadow-lg transition duration-300 ${activeTab === "transfer"
                ? "active bg-primary text-white"
                : "bg-zinc-400 text-black"
                }`}
            >
              Transfer
            </button>
            <button
              onClick={() => {
                setActiveTab("hourly");
                setFormData({ ...formData, rdJourneyCategory: "hourly" });
              }}
              className={`px-8 py-3 w-2/4 hover:shadow-lg transition duration-300 ${activeTab === "hourly"
                ? "active  bg-primary text-white"
                : "bg-zinc-400 text-black"
                }`}
            >
              Hourly
            </button>
          </div>
          <div className="form-wrapper pt-6 w-full">
            <form onSubmit={handleSubmit} className="w-full">
              <div className=" relative top-5 z-[999]">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.000000pt"
                    height="15.000000pt"
                    viewBox="0 0 29.000000 30.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M96 249 c-38 -30 -35 -73 8 -127 19 -24 38 -43 41 -43 4 0 22 20 41
                                                    45 41 53 44 93 11 124 -29 28 -67 28 -101 1z m62 -56 c-4 -22 -22 -20 -26 1
                                                    -2 10 3 16 13 16 10 0 15 -7 13 -17z"
                      />
                      <path
                        d="M39 92 c-30 -25 -20 -48 28 -61 75 -21 188 -6 200 26 7 19 -30 53
                                                    -58 53 -30 0 -22 -17 11 -23 67 -14 14 -47 -75 -47 -89 0 -142 33 -75 47 17 3
                                                    30 9 30 14 0 16 -37 10 -61 -9z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className=" mb-6">
                <PlacesAutocomplete
                  value={originAddress}
                  onChange={SetOriginAddress}
                  onSelect={originhandleSelect}
                  searchOptions={{
                    componentRestrictions: { country: "GB" },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="w-full relative">
                      <input
                        onKeyUp={(e) => {
                          const searchLocations = locations;
                          const filter = searchLocations.filter(location =>
                            location.name.toLowerCase().includes(e.target.value.toLowerCase())
                          );

                          setFilteredLocations(filter);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleDropdown('pickup');
                        }}
                        {...getInputProps({
                          placeholder: "Pick Up - Location/Address",
                          className: `text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError && clientError?.context?.key === "pickup_location"
                            ? "border-4 border-red-500"
                            : "border border-gray-300"
                            }`,
                        })}
                        value={originAddress}
                      />
                      {showDropdown.pickup && (
                        <ul className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll overflow-x-hidden z-[9999] absolute" ref={btnDropdownRef}>
                          {
                            filteredLocations?.length > 0 ? (
                              filteredLocations.map((option) => (
                                // <li key={option.id} className="hover:bg-slate-200 mt-1 p-1" data-id={option.id}>
                                  <button key={option.id} className="hover:bg-slate-200 mt-1 p-1 w-full text-left " onClick={() => handleLocationChange(option, 'pickup')}>{option.name}</button>
                                // </li>
                              ))
                            ) : (
                              suggestions?.length > 0 && (
                                suggestions.map((suggestion, index) => {
                                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                  const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                                  return (
                                    <div
                                      className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll z-[9999] absolute"
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span className="p-1">{suggestion.description}</span>
                                    </div>
                                  );
                                })
                              )
                            )}
                        </ul>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>

              <div className="relative top-5 z-[999]">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.000000pt"
                    height="15.000000pt"
                    viewBox="0 0 29.000000 30.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M50 145 c0 -70 4 -115 10 -115 6 0 10 24 10 54 l0 55 43 3 42 3 3 58
                                    3 57 -56 0 -55 0 0 -115z"
                      />
                      <path
                        d="M180 175 c0 -43 -1 -45 -30 -45 -23 0 -30 -4 -30 -20 0 -18 7 -20 71
                                    -20 69 0 70 1 63 23 -5 12 -8 31 -8 42 0 11 3 30 8 43 7 20 4 22 -33 22 l-41
                                    0 0 -45z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className=" mb-6 relative">
                <PlacesAutocomplete
                  value={destinationAddresses[0] || ''} 
                  onChange={(value) => setDestinationAddressesByIndex(value, 0)}
                  onSelect={(value) => destinationhandleSelect(value, 0)}
                  searchOptions={{
                    componentRestrictions: { country: "GB" },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        onKeyUp={(e) => {
                          const searchLocations = locations;
                          const filter = searchLocations.filter(location =>
                            location.name.toLowerCase().includes(e.target.value.toLowerCase())
                          );

                          setFilteredLocations(filter);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleDropdown('dropoff');
                        }}
                        {...getInputProps({
                          placeholder: "Drop Off- Location/Address",
                          className: `bg-gray-50  text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError &&
                            clientError?.context?.key === "dropoff_location"
                            ? "border-4 border-red-500"
                            : "border border-gray-300"
                            }`,
                        })}
                        value={destinationAddresses[0] || ''}
                      />
                      {showDropdown.dropoff && (
                        <ul className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll overflow-x-hidden z-[9999] absolute">
                          {
                            filteredLocations?.length > 0 ? (
                              filteredLocations.map((option) => (
                                // <li key={option.id} className="hover:bg-slate-200 mt-1 p-1" data-id={option.id}>
                                  <button  key={option.id}  className="hover:bg-slate-200 mt-1 p-1 text-left w-full" onClick={() => handleLocationChange(option, 'dropoff', 0)}>{option.name}</button>
                                // </li>
                              ))
                            ) : (
                              suggestions?.length > 0 && (
                                suggestions.map((suggestion, index) => {
                                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                  const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                                  return (
                                    <div
                                      className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll z-[9999] absolute"
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span className="p-1">{suggestion.description}</span>
                                    </div>
                                  );
                                })
                              )
                            )}
                        </ul>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
                <div
                  onClick={addDropOffField}
                  className=" bg-primary hover:bg-sky-800 h-[42px] flex items-center p-4  cursor-pointer text-white justify-center absolute right-0 top-0 z-[99]"
                >
                  <IoAddSharp size={24} />
                </div>
              </div>
              {dropOffFields?.length > 0 &&
                dropOffFields?.map((item, i) => (
                  <div key={i}>
                    <div className="relative top-5 z-[999]">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.000000pt"
                          height="15.000000pt"
                          viewBox="0 0 29.000000 30.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M50 145 c0 -70 4 -115 10 -115 6 0 10 24 10 54 l0 55 43 3 42 3 3 58
                                    3 57 -56 0 -55 0 0 -115z"
                            />
                            <path
                              d="M180 175 c0 -43 -1 -45 -30 -45 -23 0 -30 -4 -30 -20 0 -18 7 -20 71
                                    -20 69 0 70 1 63 23 -5 12 -8 31 -8 42 0 11 3 30 8 43 7 20 4 22 -33 22 l-41
                                    0 0 -45z"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className=" mb-6 relative">
                      <PlacesAutocomplete
                        value={destinationAddresses[i + 1]}
                        onChange={(value) =>
                          setDestinationAddressesByIndex(value, i + 1)
                        }
                        onSelect={(value) =>
                          destinationhandleSelect(value, i + 1)
                        }
                        searchOptions={{
                          componentRestrictions: { country: "GB" },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              onKeyUp={(e) => {
                                const searchLocations = locations;
                                const filter = searchLocations.filter(location =>
                                  location.name.toLowerCase().includes(e.target.value.toLowerCase())
                                );

                                setFilteredLocations(filter);
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                toggleDropdown(`dropoff${i}`);
                              }}
                              {...getInputProps({
                                placeholder: "Drop Off- Location/Address",
                                className: `bg-gray-50  text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError &&
                                  clientError?.context?.key ===
                                  "pickup_location"
                                  ? "border-4 border-red-500"
                                  : "border border-gray-300"
                                  }`,
                              })}
                              value={destinationAddresses[i + 1] || ''}
                            />
                            {showDropdown[`dropoff${i}`] && (
                              <ul className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll overflow-x-hidden z-[9999] absolute">
                                {
                                  filteredLocations?.length > 0 ? (
                                    filteredLocations.map((option) => (
                                      // <li key={option.id} className="hover:bg-slate-200 mt-1 p-1" data-id={option.id}>
                                        <button key={option.id} className="hover:bg-slate-200 mt-1 p-1 text-left w-full" onClick={() => handleLocationChange(option, 'additional_dropoffs', i + 1)}>{option.name}</button>
                                      // </li>
                                    ))
                                  ) : (
                                    suggestions?.length > 0 && (
                                      suggestions.map((suggestion, index) => {
                                        const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                        const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                                        return (
                                          <div
                                            className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll z-[9999] absolute"
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span className="p-1">{suggestion.description}</span>
                                          </div>
                                        );
                                      })
                                    )
                                  )}
                              </ul>
                            )}
                            {/* {showDropdown[`dropoff${i}`] && (
                              <ul className="bg-gray-50 text-gray-900 w-full text-sm block p-1 cursor-pointer h-20 overflow-y-scroll z-[9999] absolute">
                                {locations.map((option) => (
                                  <li key={option} onClick={() => handleDropdownSelect(option)} className="hover:bg-slate-200 mt-1 p-1">
                                    {option.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                            <div className="autocomplete-dropdown-container w-[450px] z-[99] ">
                              {loading && <div className="text-white">Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span className="p-1">{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div> */}
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <div
                        onClick={() => removeDropOffField(i + 1)}
                        className=" h-[42px] bg-[#ef4444] hover:bg-red-700 flex items-center p-4 cursor-pointer text-white justify-center absolute right-0 top-0 z-[99]"
                      >
                        <IoMdRemove size={24} />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="relative mb-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.000000pt"
                    height="15.000000pt"
                    viewBox="0 0 28.000000 29.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,29.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M40 140 l0 -100 55 0 c42 0 60 -5 75 -20 26 -26 64 -26 90 0 26 26
                                            26 64 0 90 -15 15 -20 33 -20 75 l0 55 -100 0 -100 0 0 -100z m180 65 c0 -12
                                            -16 -15 -80 -15 -64 0 -80 3 -80 15 0 12 16 15 80 15 64 0 80 -3 80 -15z m-70
                                            -55 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
                                            m-40 -40 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
                                            -10z m138 -12 c15 -15 15 -51 0 -66 -15 -15 -51 -15 -66 0 -28 28 -7 78 33 78
                                            12 0 26 -5 33 -12z"
                      />
                      <path
                        d="M206 92 c-9 -15 12 -55 25 -46 6 3 6 10 1 17 -5 7 -11 19 -14 27 -3
                                            10 -6 11 -12 2z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="input-date-wrapper flex flex-row">
                  <div
                    id="input-group-1"
                    className={`bg-gray-50  text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError && clientError?.context?.key === "pickup_date"
                      ? "border-4 border-red-500"
                      : "border border-gray-300"
                      }`}
                  >
                    <DatePicker
                      selected={
                        formData?.pickup_date
                          ? new Date(formData.pickup_date)
                          : null
                      }
                      placeholderText="Pick Up Date"
                      minDate={new Date()}
                      onChange={(date) => {
                        const newDate = date.toISOString();
                        setFormData({ ...formData, pickup_date: newDate });
                      }}
                    />
                  </div>

                  <select
                    name=""
                    id=""
                    className={`ml-2 mr-2 bg-gray-50  text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError && clientError?.context?.key === "pickup_hour"
                      ? "border-4 border-red-500"
                      : "border border-gray-300"
                      }`}
                    onChange={(e) => {
                      setFormData({ ...formData, pickup_hour: e.target.value });
                    }}
                  >
                    <option value="">HH</option>
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                  </select>

                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        pickup_minute: e.target.value,
                      });
                    }}
                    className={`bg-gray-50  text-gray-900 text-sm block w-full pl-10 p-2.5 ${clientError &&
                      clientError?.context?.key === "pickup_minute"
                      ? "border-4 border-red-500"
                      : "border border-gray-300"
                      }`}
                  >
                    <option value="">MM</option>
                    <option value="00">00</option>
                    <option value="05">05</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                  </select>
                </div>
              </div>
              <div className="submit-btn-wrapper flex">
                <div className="submit-btn-wrapper-clip"></div>
                <button
                  type="submit"
                  className="uppercase gradient-btn text-white px-6 py-2 font-semibold"
                >
                  Get a quote
                </button>
                <div className="div-bar"></div>
                <div className="div-bar"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
