import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Loader from "components/Shared/Loader";
import { useAppContext } from "context/app-context";
import http from "config/axios";
import { preferences } from "joi";
import toast from "react-hot-toast";

const CustomerDashboard = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [pending, setPending] = React.useState(true);
  const [userType, setUserType] = React.useState('user');
  const [columns, setColumns] = React.useState([
    {
      name: "Order No",
      selector: (row) => row.order_no,
    },
    {
      name: "Pickup",
      selector: (row) => row.pickup_location,
    },
    {
      name: "Dropoff",
      selector: (row) => row.dropoff_location,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status.charAt(0).toUpperCase() + row.status.slice(1),
    },
    
  ]);
  const [data, setData] = useState({
    pending: [],
    completed: [],
    rejected: [],

  });

  const appContext = useAppContext();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Set "Pending" tab as completed on initial render
  useEffect(() => {
    handleTabChange("pending");
    fetchData();
  }, []);


  useEffect(() => {
    // Check if the "Action" column is already present
    const actionColumnExists = columns.some((col) => col.name === 'Action');
  
    // Check if the activeTab is "pending" and the "Action" column exists
    if (userType === 'driver' && activeTab === 'pending' && !actionColumnExists) {
      setColumns((prevColumns) => [
        ...prevColumns,
        {
          name: 'Action',
          cell: (row) => (
            row.status === 'pending' || row.status === 'assigned' && (
              <button onClick={() => handleStatus(row.id)} className="bg-primary text-white px-2 py-1 rounded-sm">Complete</button>
            )
          ),
        },
      ]);
    } else if (activeTab !== 'pending' && actionColumnExists) {
      // If activeTab is not "pending" and the "Action" column exists, remove it
      setColumns((prevColumns) => prevColumns.filter((col) => col.name !== 'Action'));
    }
  }, [userType, activeTab, columns]);

  // driver will update his booking status
  const handleStatus = (id) => {

    try {
      // Make an API call to update the status
      http
        .post("driverBookingStatus", { id, status: "completed" }, {
          headers: {
            Authorization: "Bearer " + appContext?.state?.token,
          },
        })
        .then((response) => {
          toast.success( response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Fetch data from the API
  const fetchData = () => {
    try {
      http
        .get("bookings", {
          headers: {
            Authorization: "Bearer " + appContext?.state?.token,
          },
        })
        .then((response) => {

          const pendingData = response.data.bookings.filter(
            (item) => item.status === "pending" || item.status === "assigned"
          );

          const activeData = response.data.bookings.filter(
            (item) => item.status === "completed"
          );
          const suspendedData = response.data.bookings.filter(
            (item) => item.status === "rejected"
          );

          setUserType(response.data.userType);
          

          setData({
            pending: pendingData,
            completed: activeData,
            rejected: suspendedData,
          });
          setTimeout(() => {
            setPending(false);
          }, 500);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="login-form-wrapper shadow-xl w-4/5 mx-auto p-10 mt-20 mb-20">
      <div className="mt-8">
        <h2>RIDES</h2>
        {/* Tabs */}
        <div className="flex flex-row justify-center mb-5">
          <button
            onClick={() => handleTabChange("pending")}
            className={`tab-btn ml-10 ${
              activeTab === "pending"
                ? "completed-tab bg-primary text-white px-4"
                : ""
            }`}
          >
            <span className=""> Pending</span>
          </button>
          <button
            onClick={() => handleTabChange("completed")}
            className={`tab-btn ml-10 ${
              activeTab === "completed"
                ? "completed-tab bg-primary text-white px-4"
                : ""
            }`}
          >
            <span className=""> Completed </span>
          </button>
          <button
            onClick={() => handleTabChange("rejected")}
            className={`tab-btn ml-10 ${
              activeTab === "rejected"
                ? "completed-tab bg-primary text-white px-4"
                : ""
            }`}
          >
            <span className=""> Rejected </span>
          </button>
        </div>

        {/* Tab Content */}
        <div>
          {activeTab === "pending" && data.pending ? (
            data.pending.length > 0 ? (
              <DataTable
                columns={columns}
                data={data.pending}
                selectableRows
                pagination
                striped={true}
                paginationPerPage={5}
                fixedHeader
                progressPending={pending}
                progressComponent={<Loader />}
              />
            ) : (
              <p>No records.</p>
            )
          ) : null}

          {activeTab === "completed" && data.completed ? (
            data.completed.length > 0 ? (
              <DataTable
                columns={columns}
                data={data.completed}
                selectableRows
              />
            ) : (
              <p>No records.</p>
            )
          ) : null}

          {activeTab === "rejected" && data.rejected ? (
            data.rejected.length > 0 ? (
              <DataTable
                columns={columns}
                data={data.rejected}
                selectableRows
              />
            ) : (
              <p>No records.</p>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
