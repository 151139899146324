import React from "react";

import Hero from "../components/Hero/Hero";
import PopularServices from "../components/Popular/PopularServices";
import DifferentServices from "components/differentServices/DifferentServices";
import ChooseUs from "components/chooseUs/ChooseUs";
import Faq from "components/Faq/FAQ";
import ContactSupport from "components/contactSupport/ContactSupport";
import hotelImg from '../assets/img/southend-hotel-service.png'
import safeImg from "../assets/img/southend-safe.png";
import pocketImg from "../assets/img/southend-pocket-friendly.png";
import luxuriousImg from "../assets/img/southend-luxurious.png";
import driverImg from "../assets/img/southend-drivers.png";
import costEffectiveImg from "../assets/img/southend-cost-effective.png";
import comfortImg from "../assets/img/southend-comfort.png";
import BookingProcess from "components/bookingProcess/BookingProcess";
import { Link } from "react-router-dom";

const data = [
  {
    title: "SOUTHEND CITY Hotel TRANSFERS",
    subTitle:
      "Book Your Hotel Transfer with Southend City Transfer Today and Experience Seamless Travel!",
    img: hotelImg,
    text: [
      "Traveling into and out of the world's major airports can be a difficult experience. Allow Southend City to relieve some of your worries by providing London hotel transfer services. Our services can take you to any airport, as well as the greatest hotels in London. Our airport-to-hotel transfer service allows you to arrive in comfort and luxury at your important business meeting, any tourist attraction, any hotel, or your home. We offer trustworthy and reasonably priced airport transfers from Heathrow and other airports to any hotel or destination in Southend.",
    ],
  
  },
];

const faqData = [
  {
    ques: "Is the hotel transportation service available around the clock?",
    servicse: [],
    ans: "Yes, we provide hotel transfers 24 hours a day, seven days a week. We understand that flights can arrive at any time, thus our drivers are available to pick you up at any time.",
  },
  {
    ques: "Can I make additional stops or take detours during my hotel transfer?",
    servicse: [],
    ans: "Yes, we recognize that you may choose to make additional stops or detours throughout your move. We recommend advising us of any special needs when making your reservation, and we will try our best to meet those requirements.",
  },
  {
    ques: "From which hotels provide Southend City Transfers services?",
    servicse: [
      "Hilton London Park Lane",
      "shanghai Lala London",
      "Novotel London West",
      "Novotel London Canary Wharf",
      "Novotel London Blackfriars",
      "London Premier Inn",
      "Hilton London Paddington",
      "London four seasons",
      "Marriott Hotel in London",
    ],
    ans: "We provide transport to and from the following London hotels:",
  },
];

const chooseusData = [
  {
    img: luxuriousImg,
    title: "Pickup at the airport",
    description:
      "Our driver will meet you at the airport terminal to make things easy for you. Simply walk to the waiting area to meet your driver after you have collected your luggage. We value you and do not want you to be inconvenienced, so we make transportation from the airport to the hotel convenient for you.",
  },
  {
    img: pocketImg,
    title: "Cashless Payment",
    description:
      "We understand that not everyone brings cash with them. As a result, our airport transfer service accepts cashless payments. At the end of your ride, the credit card associated with your account will be charged.",
  },
  {
    img: comfortImg,
    title: "Flight Tracking",
    description:
      "We understand that not everyone brings cash with them. As a result, our airport transfer service accepts cashless payments. At the end of your ride, the credit card associated with your account will be charged.",
  },
  {
    img: safeImg,
    title: "Private and Personalised Service",
    description:
      "Our hotel transfer services are private, you will have the entire car to yourself or your company. This provides for a more personalized experience; we ensure that you have a comfortable vehicle that meets your requirements.",
  },
  {
    img: costEffectiveImg,
    title: "Variety of Vehicles to Choose From",
    description:
      "We provide a large selection of vehicles to meet all of your needs. Whether you require a premium Mercedes for a business meeting or a minivan for a group, we have you covered.",
  },
  {
    img: driverImg,
    title: "Airport transfer service that is safe and convenient",
    description:
      "We provide seamless transfer services from the hotel to the airport, removing the need to navigate public transportation or haggle with taxi drivers. You don't know anything about taxi drivers, which makes you feel worried but our drivers are subjected to rigorous scrutiny, so you know you're in good hands.",
  },
];

const HotelService = () => {
  return (
    <>
      <Hero heading="Hotel Transfers" />

      <DifferentServices data={data} type="hotel" />
      <ChooseUs
        data={chooseusData}
        subTitle="WHY CHOOSE"
        title="Southend City for Hotel Transfers?"
      />
      <Faq data={faqData} title="Hotel  SERVICE" />
      <ContactSupport />
      <div className="mt-20">
        <PopularServices />
      </div>
      <BookingProcess />
      <div className="mt-10 mb-10 flex justify-center">
        <Link to="/" className="uppercase bg-gradient-to-r from-[#002041] to-[#004080] text-white px-6 py-2 font-semibold">Book Now</Link>
      </div>
    </>
  );
};

export default HotelService;
