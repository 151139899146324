import React, { useEffect, useState } from "react";
import { IconButton, ButtonGroup } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function Pagination({ links, activePage }) {

    const [preUrl, setPreUrl] = useState("");
    const [nextUrl, setNextUrl] = useState("");

    useEffect(() => {
        if (links?.prev_page_url) {
            const params = new URLSearchParams(new URL(links?.prev_page_url).search);
            setPreUrl(params.get('page'));
        }
        if (links?.next_page_url) {
            const params = new URLSearchParams(new URL(links?.next_page_url).search);
            setNextUrl(params.get('page'));
        }
    }, [links?.prev_page_url, links?.next_page_url]);  // Dependencies for useEffect


    return (
        <div className="container mx-auto px-4">
            <nav className="flex flex-row flex-nowrap justify-between md:justify-center items-center" aria-label="Pagination">
                {
                    links?.links && links.links.map((item, i) => {
                        const isFirst = i === 0;
                        const isLast = i === links.links.length - 1;
                        return (<>
                            {
                                isFirst && (
                                    <span className={`flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-700 cursor-pointer ${links?.prev_page_url === null ? 'bg-slate-400 text-white pointer-events-none' : ''}`} href={item.url} title="Previous Page" onClick={() => 
                                    activePage(preUrl)}>
                                        <span className="sr-only">Previous Page</span>
                                        <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
                                            <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                                        </svg>
                                    </span>
                                )
                            }
                            {
                                !isFirst && !isLast ? <span className={`flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-700 cursor-pointer ${item?.active ? 'bg-black text-white pointer-events-none' : ''}`} href={item.url} title={item.label} onClick={() => activePage(item.label)} key={item.label}>
                                    {item.label}
                                </span> : ''
                            }

                            {
                                isLast && (
                                    <span className={`flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-700 cursor-pointer ${links?.next_page_url === null ? 'bg-slate-400 text-white pointer-events-none' : ''}`} href={item.url} title="Next Page" onClick={() => activePage(nextUrl)}>
                                        <span className="sr-only">Next Page</span>
                                        <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
                                            <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                                        </svg>
                                    </span>
                                )
                            }
                        </>)
                    })
                }
            </nav>
        </div>
    );
}