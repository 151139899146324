import React from 'react';
import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { HiOutlineChevronRight } from "react-icons/hi";

const clientData = [
  {
    id: 1,
    description:
      "As their Loyal Customer I have already collected quite a big deal of bonuses that can cover a few rides with this company...",
    name: "Phillipo Calista London, UK",
    image: "testimonial1.png",
  },
  {
    id: 2,
    description:
      "As their Loyal Customer I have already collectedquite a big deal of bonuses.",
    name: "Phillipo London, UK",
    image: "testimonial2.png",
  },
  {
    id: 3,
    description: "As their Loyal Customer I have already collectedquite.",
    name: "Phillipo, UK",
    image: "testimonial3.png",
  },
];

const Testimonial = () => {
  const [activeItem, setActiveItem] = useState(0);

  const handleSubmit = (direction) => {
    if (direction === "next") {
      activeItem < clientData.length - 1
        ? setActiveItem(activeItem + 1)
        : setActiveItem(0);
    } else {
      activeItem === 0
        ? setActiveItem(clientData.length - 1)
        : setActiveItem(activeItem - 1);
    }
  };

  return (
    <div className=" w-full flex justify-center relative h-[68vh]  -mt-14 z-[1]   object-cover  bg-[url('../../assets/img/southend-city-transfers-testimonial.png')]" style={{backgroundRepeat: "no-repeat", backgroundSize: 'cover'}}>
      <div className=" h-full w-full absolute left-0 top-0 bg-[#0009] z-[2]"></div>
      <div className="   z-[3]  flex flex-col justify-center w-full md:w-[35%]  px-2 ">
        <div className=" flex flex-col text-center">
          <h5 className=" uppercase text-[16px] lg:text-xl font-bold tracking-wider text-white">
            TESTIMONIAL
          </h5>
          <h3 className=" text-[20px] lg:text-3xl   font-bold leading-10 tracking-wider text-white">
            What clients say about us?
          </h3>
        </div>

        <div className=" flex flex-col items-center justify-center mt-4 ">
          <p className=" text-white text-md   text-center  mt-14  w-full">
            {clientData[activeItem].description}
          </p>
          <span className=" text-gray-400 text-sm  mt-7">
            {clientData[activeItem].name}
          </span>
        </div>

        <div className=" flex gap-2 justify-center  mt-10">
          <div className=" w-[50px] h-[50px]">
            <img
              src={require(`../../assets/img/${clientData[0].image}`)}
              alt=""
              className=" w-full h-full rounded-full object-cover"
            />
          </div>
          <div className=" w-[50px] h-[50px]">
            <img
              src={require(`../../assets/img/${clientData[1].image}`)}
              alt=""
              className=" w-full h-full rounded-full object-cover"
            />
          </div>
          <div className=" w-[50px] h-[50px]">
            <img
              src={require(`../../assets/img/${clientData[2].image}`)}
              alt=""
              className=" w-full h-full rounded-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className=" w-full absolute top-0 left-0 flex justify-between items-center h-full z-[99]">
        <div
          className="group-hover:block border border-custom_yellow absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 cursor-pointer"
          onClick={() => handleSubmit("prev")}
        >
          <BsChevronLeft
            size={20}
            style={{
              color: "#FECA01",
            }}
          />
        </div>
        <div
          className="group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 border border-custom_yellow cursor-pointer"
          onClick={() => handleSubmit("next")}
        >
          <BsChevronRight
            size={20}
            style={{
              color: "#FECA01",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
