import BookingHeader from "components/Shared/BookingHeader";
import { useAppContext } from "context/app-context";
import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";

import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/bootstrap.css";
import http from "config/axios";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import Joi from "joi";

const BookingDetails = () => {
  const appContext = useAppContext();
  const [booking, setBooking] = useState({
    distance: appContext?.state?.booking?.distance,
    duration: appContext?.state?.booking?.duration,
    pickup_location: appContext?.state?.booking?.pickup_location,
    dropoff_location: appContext?.state?.booking?.dropoff_location,
    rdJourneyCategory: appContext?.state?.booking?.rdJourneyCategory,
    paymentMode: "Paypal",
    fare: appContext?.state?.booking?.fare,
  });

  const [pickupFields, setPickupFields] = useState([]);
  const [dropoffFields, setDropoffFields] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [formData, setFormData] = useState({
    pickup_location: appContext?.state?.booking?.pickup_location,
    pickup_lat_lon: appContext?.state?.booking?.pickup_lat_lon,
    pickup_postal_code: appContext?.state?.booking?.pickup_postal_code,
    dropoff_location: appContext?.state?.booking?.dropoff_location,
    dropoff_lat_lon: appContext?.state?.booking?.dropoff_lat_lon,
    dropoff_postal_code: appContext?.state?.booking?.dropoff_postal_code,
    fare: appContext?.state?.booking?.fare,
    total_distance: appContext?.state?.booking?.distance,
    payment_mode: booking?.paymentMode,
    rdJourneyCategory: appContext?.state?.booking?.rdJourneyCategory,
    date_hour: appContext?.state?.booking?.pickup_hour,
    date_minute: appContext?.state?.booking?.pickup_minute,
    return_date_hour: appContext?.state?.booking?.return_pickup_hour,
    return_date_minute: appContext?.state?.booking?.return_pickup_minute,
    return_pickup_date: appContext?.state?.booking?.return_pickup_date,
    isReturn: appContext?.state?.booking?.isReturn,
    pickup: [],
    dropoff: {},
    vehicle: {
      hand_laggage: 0,
      check_in_laggage: 0,
      child_seats_capacity: 0,
      passenger: 1
    },
    customer_email: appContext?.state.user?.email,
    customer_name: appContext?.state.user?.name,
    customer_phone: "",
    notes: null,
    return_notes: null,
    pickup_date: ""
  });

  const [paymentBtn, setPaymentBtn] = useState({
    Paypal: false,
    Stripe: false,
  });


  const handleChange = (e, locationName) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      dropoff: {
        ...prevState.dropoff,
        [locationName]: {
          ...prevState.dropoff?.[locationName],
          [name]: value,
        },
      },
    }));
  };

  useEffect(() => {
    if (vehicles.length > 0) {
      setSelectedVehicle(vehicles[0]);
    }
  }, [vehicles]);

  useEffect(() => {
    if (selectedVehicle) {
      const { id, name } = selectedVehicle;
      setFormData((prevFormData) => ({
        ...prevFormData,
        vehicle: {
          ...formData.vehicle,
          vehicle_name: name,
          vehicle_id: id,
        },
      }));
    }
  }, [selectedVehicle]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clientError, setClientError] = useState(null);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (!booking?.pickup_location || !booking?.dropoff_location) {
      navigate("/");
    }
  }, [booking?.pickup_location, booking?.dropoff_location]);

  const getVehicles = async () => {
    await http
      .get("/getAllVehicles")
      .then((response) => {
        setVehicles(response.data.vehicles);
      })
      .catch((error) => console.log(error));
  };

  const getPickupLocationFields = async () => {
    await http
      .post("/getPickupLocationFields", {
        pickup_location: booking?.pickup_location,
      })
      .then((response) => {
        setPickupFields(response.data.location);
      })
      .catch((error) => console.log(error));
  };

  const getDropoffLocationFields = async () => {
    await http
      .post("/getDropoffLocationFields", {
        dropoff_location: booking?.dropoff_location,
      })
      .then((response) => {
        setDropoffFields(response.data.location);
      })
      .catch((error) => console.log(error));
  };

  const createOptions = (capacity, k) => {
    const options = [];
    for (let i = k; i <= capacity; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  useEffect(() => {
    if (booking?.pickup_location && booking?.dropoff_location) {
      getPickupLocationFields();
      getDropoffLocationFields();
      getVehicles();
    }
    // return () => {
    //   navigate("/");
    // };
  }, [booking?.pickup_location, booking?.dropoff_location]);

  // useEffect(() => {
  //   const fareFromLocalStorage = localStorage.getItem("fare");
  //   if (booking?.fare === 0 && fareFromLocalStorage) {
  //     const fare = localStorage.getItem("fare");
  //     setBooking({
  //       ...booking,
  //       fare: fare,
  //     });
  //     const existingDataString = localStorage.getItem("persist");
  //     const existingData = existingDataString
  //       ? JSON.parse(existingDataString)
  //       : {};
  //     existingData.booking.fare = fare;
  //     localStorage.setItem("persist", JSON.stringify(existingData));

  //     appContext.dispatch({
  //       type: appContext.actions.UPDATE,
  //       data: {
  //         booking: booking,
  //       },
  //     });
  //   }
  // }, [booking]);

  //validation

  // Function to create Joi schema for each field

  // const generatePickupSchema = (pickupFields) => {
  //   const schema = {};

  //   if (pickupFields !== null) {
  //     pickupFields.forEach((field) => {
  //       schema[field.label] = Joi.string().required();
  //     });

  //     return Joi.object(schema);
  //   }
  //   return Joi.object().required();
  // };
  const generatePickupSchema = (pickupFields) => {
    const schema = {};
  
    if (pickupFields !== null && Array.isArray(pickupFields)) {
      pickupFields.forEach((field) => {
        schema[field.label] = Joi.string().required();
      });
  
      return Joi.array().items(Joi.object(schema)).required();
    }
  
    return Joi.array().items(Joi.object()).optional();
  };

  const generateVehicleSchema = (pickupFields) => {
    const schema = {};

    if (pickupFields !== null) {
      pickupFields.forEach((field) => {
        schema[field.label] = Joi.string().required();
      });

      return Joi.object(schema);
    }
    return Joi.object().required();
  };

  // const generateDropoffSchema = (dropoffFields) => {
  //   const schema = {};
  //   if (dropoffFields !== null) {
  //     dropoffFields.forEach((field) => {
  //       schema[field.label] = Joi.string().required();
  //     });

  //     return Joi.object(schema);
  //   }
  //   return Joi.object().required();
  // };
  // const generateDropoffSchema = (dropoffFields) => {
  //   console.log("dropoffField", dropoffFields);
  //   const schema = {};
  //   // if (dropoffFields !== null && typeof dropoffFields === 'object') {
  //   //   Object.values(dropoffFields).forEach((fieldsJson) => {
  //   //     const fields = JSON.parse(fieldsJson);
  //   //     fields.forEach((field) => {
  //   //       schema[field.label] = Joi.string().required();
  //   //     });
  //   //   });
  
  //   //   return Joi.object(schema);
  //   // }
  //   return Joi.object().required();
  // };

  const generateDropoffSchema = (dropoffFields) => {
    const schema = {};
  
    if (dropoffFields !== null && typeof dropoffFields === 'object') {
      Object.entries(dropoffFields).forEach(([location, fields]) => {
        if (fields !== null && typeof fields === 'object') {
          const locationSchema = {};
          Object.entries(fields).forEach(([fieldLabel, fieldValue]) => {
            locationSchema[fieldLabel] = Joi.string().required();
          });
          schema[location] = Joi.object(locationSchema).required();
        }
      });
  
      return Joi.object().pattern(/.*/, Joi.object().required());
    }
  
    return Joi.object().pattern(/.*/, Joi.object()).optional();
  };

  const schema = Joi.object({
    // pickup: generatePickupSchema(pickupFields).required() || Joi.optional(),
    // dropoff: generateDropoffSchema(dropoffFields).required() || Joi.optional(),
    pickup:  Joi.optional(),
    dropoff:  Joi.optional(),
    pickup_location: Joi.string().required(),
    dropoff_location: Joi.array().required(),
    fare: Joi.number().required(),
    total_distance: Joi.number().required(),
    payment_mode: Joi.string().required(),
    rdJourneyCategory: Joi.string().required(),
    date_hour: Joi.string().required(),
    date_minute: Joi.string().required(),
    customer_email: Joi.string().required(),
    customer_name: Joi.string().required(),
    customer_phone: Joi.required(),
    notes: Joi.string().required(),

  }).unknown(true);

  const handleBookingForm = async (e) => {
    e.preventDefault();
    const validation = schema.validate(formData);
    console.log("validation", validation);
    if (validation?.error) {
      setClientError(validation?.error?.details[0])
      toast.error("Please fill the above filed");
      console.log(clientError);
    } else {
      const existingDataString = localStorage.getItem("persist");
      const existingData = existingDataString
        ? JSON.parse(existingDataString)
        : {};
      existingData.booking = formData;
      localStorage.setItem("persist", JSON.stringify(existingData));

      appContext.dispatch({
        type: appContext.actions.UPDATE,
        data: {
          ...appContext.state,
          booking: {
            ...appContext.state.booking,
          },
        },
      });
      if (formData?.payment_mode === "Paypal") {
        window.location.href = "/payment/paypal";
      } else {
        // navigate("/payment/stripe");
        window.location.href = '/payment/stripe';
      }
      console.log(formData);

    }

    // await http
    //   .post("/createBooking", formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.success) {
    //       toast.success(response.data.message);
    //       navigate("/booking");
    //     }

    //     if (!response.data.success) {
    //       toast.error(response.data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.response.data.message);
    //   });
  };


  useEffect(() => {
    if(formData?.total_distance == undefined){
      window.location.href = '/';
    }
  },[formData?.total_distance]);

  useEffect(() => {
    if(formData.isReturn){
      setFormData({
       ...formData,
       fare: formData.fare * 2,
      });
    }else{
      setFormData({
        ...formData,
        fare: appContext?.state?.booking?.fare,
       });
    }
  }, [formData.isReturn]);

  const handlePaymentMode = (mode) => {
    setFormData({
      ...formData,
      payment_mode: mode,
    });


    // Update the paymentBtn state based on the clicked mode
    if (mode === "Paypal") {
      setPaymentBtn({
        ...paymentBtn,
        Paypal: true,
        Stripe: false,
      });
      setFormData({
        ...formData,
        payment_mode: "Paypal",
      });
    } else if (mode === "Stripe") {
      setPaymentBtn({
        ...paymentBtn,
        Stripe: true,
        Paypal: false,
      });
      setFormData({
        ...formData,
        payment_mode: "Stripe",
      });
    }
  };

  useEffect(() => {
    const date = new Date(appContext?.state?.booking?.pickup_date);
    setFormData({
      ...formData,
      pickup_date: date,
    });
  }, [appContext?.state?.booking?.pickup_date]);
  return (
    <div className="w-full flex flex-col items-center mt-20">
      <BookingHeader
        pickup_location={booking?.pickup_location}
        dropoff_location={booking?.dropoff_location.slice(-1)}
        duration={booking?.duration}
        distance={booking?.distance}
      ></BookingHeader>

      <div className="card card-body mb-3 w-full md:w-4/5 mt-5 mx-2 border border-gray-300 p-5">
        <div className="flex flex-col lg:flex-row justify-between mb-3">
          <div className="text-lg mb-lg-0">
            Journey 1:-
            <span className="bg-green-500 text-white px-2 py-1 rounded ml-2">
              Fare: <span id="journeyFareCurrency">£</span>
              <span id="journeyFare">{booking?.fare}</span>
            </span>
          </div>
          <div className="flex flex-col md:flex-row items-center mt-2 md:mt-0">
            <label className="mr-5 w-full sm:w-[60%] text-center md:w-fit">
              Pick Up Date &amp; Time
            </label>
            <div className="border w-full sm:w-[60%] md:w-fit text-center   border-1 p-1 border-gray-200 rounded-sm">
              <DatePicker
                selected={formData?.pickup_date}
                placeholderText="Pick Up Date"
                onChange={(date) =>
                  setFormData({ ...formData, pickup_date: date })
                }
              />
            </div>
            <div className="ml-2 p-1 w-full sm:w-[60%] md:w-fit text-center">
              <select
                name="selDateHr"
                id="selDateHr"
                className="form-select border rounded py-1 px-2 w-full"
                required=""
                onChange={(e) =>
                  setFormData({ ...formData, date_hour: e.target.value })
                }
                value={formData?.date_hour}
              >
                <option value="">HH</option>
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>
            </div>
            <div className="p-1 ml-2 w-full sm:w-[60%] md:w-fit text-center">
              <select
                name="selDateMn"
                id="selDateMn"
                className="form-select border rounded py-1 px-2 w-full"
                required=""
                onChange={(e) =>
                  setFormData({ ...formData, date_minute: e.target.value })
                }
                value={formData?.date_minute}
              >
                <option value="">MM</option>
                <option value="00">00</option>
                <option value="05">05</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
              </select>
            </div>
          </div>
        </div>
        <hr />

        <div className="mt-5 p-3">
          <div className="flex flex-col md:flex-row  items-center justify-between">
            <div className="border mr-4 broder-gray-200 border-1 p-2 bg-[#dfe0e5] rounded-sm w-full sm:w-[80%] md:max-w-[32%]">
              <label className="block mb-2 text-sm font-medium text-black">
                Pick Up
              </label>
              <div className="">{booking?.pickup_location}</div>
            </div>

            {/* pickup_fields */}
            {pickupFields &&
              pickupFields.map((item, index) => (
                <div
                  className="flex flex-row w-full sm:w-[80%] md:w-[30%] gl:w-[20%]"
                  key={index}
                >
                  <div className="flex-1 border broder-gray-200 border-1 p-2">
                    <label className="block mb-2 text-sm font-medium p-1 text-black">
                      {item.label}
                    </label>
                    {item.inputType === "Select" ? (
                      <select
                        className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                            ? "border border-red-600 "
                            : "border border-gray-200 "
                          }`}
                        required={item.type === "required" ? "required" : ""}
                        name={item.label}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pickup: [
                              {
                                ...formData.pickup,
                                [e.target.name]: e.target.value,
                              }
                            ],
                          })
                        }
                      >
                        <option>Choose</option>
                        {item.selectOption.split(",").map((option, i) => (
                          <option key={i} value={option.trim()}>
                            {option.trim()}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        name={item.label}
                        className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                            ? "border border-red-500 "
                            : "border border-gray-200 "
                          }`}
                        required={item.type === "required" ? "required" : ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pickup: [{
                              ...formData.pickup,
                              [e.target.name]: e.target.value,
                            }],
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div>
      {booking?.dropoff_location.map((dropoff, i) => (
        <div key={i} className="flex flex-col md:flex-row items-center justify-between mt-5 w-full">
          <div className="border broder-gray-200 border-1 p-2 bg-[#dfe0e5] rounded-sm w-full sm:w-[80%] md:w-[32%]">
            <label className="block mb-2 text-sm font-medium text-black">
              {booking?.dropoff_location.length === 1 ? (
                <p>Dropoff</p>
              ) : (
                <p>Dropoff {i + 1}</p>
              )}
            </label>
            <div className="">{dropoff}</div>
          </div>

          {dropoffFields[dropoff] && (
            JSON.parse(dropoffFields[dropoff]).map((item, index) => (
              <div
                className="flex flex-row w-full sm:w-[80%] md:w-[30%] gl:w-[20%]"
                key={index}
              >
                <div className="flex-1 border border-gray-200 border-1 p-2">
                  <label className="block mb-2 text-sm font-medium p-1 text-black">
                    {item.label}
                  </label>
                  {item.inputType === "Select" ? (
                    <select
                      className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${
                        clientError?.context?.key === item.label
                          ? "border border-red-500"
                          : "border border-gray-200"
                      }`}
                      required={item.type === "required" ? true : false}
                      name={item.label}
                      onChange={(e) => handleChange(e, dropoff)}
                    >
                      <option>Choose</option>
                      {item.selectOption && item.selectOption.split(",").map((option, i) => (
                        <option key={i} value={option.trim()}>
                          {option.trim()}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${
                        clientError?.context?.key === item.label
                          ? "border border-red-600"
                          : "border border-gray-200"
                      }`}
                      required={item.type === "required" ? true : false}
                      name={item.label}
                      onChange={(e) => handleChange(e, dropoff)}
                    />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      ))}
    </div>
        </div>

        <div className="mt-3 mx-3">
          <label className="block mb-2 text-sm font-medium text-black">
            Notes:
          </label>
          <textarea
            className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === "notes"
                ? "border border-red-600 "
                : "border border-gray-200 "
              }`}
            onChange={(e) =>
              setFormData({
                ...formData,
                notes: e.target.value,
              })
            }
          ></textarea>
        </div>
      </div>
      <div className="w-full md:w-4/5">
        <label className="relative flex gap-2 items-center group p-2 text-xl">
          <input
            onClick={() =>
              setFormData({
                ...formData,
                isReturn: !formData.isReturn,
              })
            }
            type="checkbox"
            checked={formData.isReturn}
            className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
          />
          <div
            className={`cursor-pointer w-[70px] h-[30px] flex items-center flex-shrink-0 ml-4 p-1 ${formData.isReturn ? "bg-green-400" : "bg-gray-300"
              } rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-6 after:h-6 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-[40px] `}
          ></div>
          Return Journey
        </label>
      </div>
      {/* return component */}

      {formData.isReturn && (
        <div className="card card-body mb-3 w-full md:w-4/5 mt-5 mx-2 border border-gray-300 p-5">
          <div className="flex flex-col lg:flex-row justify-between mb-3">
            <div className="text-lg mb-lg-0">
              Journey 2:-
              <span className="bg-green-500 text-white px-2 py-1 rounded ml-2">
                Fare: <span id="journeyFareCurrency">£</span>
                <span id="journeyFare">{booking?.fare}</span>
              </span>
            </div>
            <div className="flex flex-col md:flex-row items-center mt-2 md:mt-0">
              <label className="mr-5 w-full sm:w-[60%] text-center md:w-fit">
                Pick Up Date &amp; Time
              </label>
              <div className="border w-full sm:w-[60%] md:w-fit text-center   border-1 p-1 border-gray-200 rounded-sm">
                <DatePicker
                  selected={
                    formData?.return_pickup_date
                      ? new Date(formData.return_pickup_date)
                      : null
                  }
                  placeholderText="Pick Up Date"
                  minDate={new Date()}
                  onChange={(date) => {
                    const newDate = date.toISOString();
                    setFormData({ ...formData, return_pickup_date: newDate });
                  }}
                />
              </div>
              <div className="ml-2 p-1 w-full sm:w-[60%] md:w-fit text-center">
                <select
                  name="selDateHr"
                  id="selDateHr"
                  className="form-select border rounded py-1 px-2 w-full"
                  required=""
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      return_date_hour: e.target.value,
                    })
                  }
                // value={formData?.date_hour}
                >
                  <option value="">HH</option>
                  <option value="00">00</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                </select>
              </div>
              <div className="p-1 ml-2 w-full sm:w-[60%] md:w-fit text-center">
                <select
                  name="selDateMn"
                  id="selDateMn"
                  className="form-select border rounded py-1 px-2 w-full"
                  required=""
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      return_date_minute: e.target.value,
                    })
                  }
                // value={formData?.date_minute}
                >
                  <option value="">MM</option>
                  <option value="00">00</option>
                  <option value="05">05</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                </select>
              </div>
            </div>
          </div>
          <hr />

          <div className="mt-5 p-3">
            <div className="flex flex-col md:flex-row  items-center justify-between">
              <div className="border mr-4 broder-gray-200 border-1 p-2 bg-[#dfe0e5] rounded-sm w-full sm:w-[80%] md:max-w-[32%]">
                <label className="block mb-2 text-sm font-medium text-black">
                  Pick Up
                </label>
                <div className="">{booking?.dropoff_location.slice(-1)}</div>
              </div>

              {/* pickup_fields */}
              {pickupFields &&
                pickupFields.map((item, index) => (
                  <div
                    className="flex flex-row w-full sm:w-[80%] md:w-[30%] gl:w-[20%]"
                    key={index}
                  >
                    <div className="flex-1 border broder-gray-200 border-1 p-2">
                      <label className="block mb-2 text-sm font-medium p-1 text-black">
                        {item.label}
                      </label>
                      {item.inputType === "Select" ? (
                        <select
                          className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                              ? "border border-red-600 "
                              : "border border-gray-200 "
                            }`}
                          required={item.type === "required" ? "required" : ""}
                          name={item.label}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              pickup: {
                                ...formData.pickup,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                        >
                          <option>Choose</option>
                          {item.selectOption.split(",").map((option, i) => (
                            <option key={i} value={option.trim()}>
                              {option.trim()}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name={item.label}
                          className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                              ? "border border-red-500 "
                              : "border border-gray-200 "
                            }`}
                          required={item.type === "required" ? "required" : ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              pickup: {
                                ...formData.pickup,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>

            {booking?.dropoff_location
              ?.slice(0, -1)
              ?.reverse()
              ?.map((dropoff, i) => (
                <div
                  className="flex flex-col md:flex-row items-center justify-between mt-5 w-full"
                  key={i}
                >
                  <div className="border border-gray-200 border-1 p-2 bg-[#dfe0e5] rounded-sm w-full sm:w-[80%] md:w-[32%]">
                    <label className="block mb-2 text-sm font-medium text-black">
                      Dropoff {i + 1}
                    </label>
                    <div>{dropoff}</div>
                  </div>

                  {/* dropoff_fields */}
                  {dropoffFields &&
                    dropoffFields.map((item, index) => (
                      <div
                        className="flex flex-row w-full sm:w-[80%] md:w-[30%] gl:w-[20%]"
                        key={index}
                      >
                        <div className="flex-1 border border-gray-200 border-1 p-2">
                          <label className="block mb-2 text-sm font-medium p-1 text-black">
                            {item.label}
                          </label>
                          {item.inputType === "Select" ? (
                            <select
                              className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                                  ? "border border-red-500"
                                  : "border border-gray-200"
                                }`}
                              required={
                                item.type === "required" ? "required" : ""
                              }
                              name={item.label}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  dropoff: {
                                    ...formData.dropoff,
                                    [e.target.name]: e.target.value,
                                  },
                                })
                              }
                            >
                              <option>Choose</option>
                              {item.selectOption.split(",").map((option, i) => (
                                <option key={i} value={option.trim()}>
                                  {option.trim()}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === item.label
                                  ? "border border-red-600"
                                  : "border border-gray-200"
                                }`}
                              required={
                                item.type === "required" ? "required" : ""
                              }
                              name={item.label}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  dropoff: {
                                    ...formData.dropoff,
                                    [e.target.name]: e.target.value,
                                  },
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              ))}

            <div className="flex flex-col md:flex-row items-center justify-between mt-5 w-full">
              <div className="border broder-gray-200 border-1 p-2 bg-[#dfe0e5] rounded-sm w-full sm:w-[80%] md:w-[32%]">
                <label className="block mb-2 text-sm font-medium text-black">
                  Dropoff  {booking?.dropoff_location.length !== 1 && booking?.dropoff_location.length}
                </label>
                <div className="">{booking?.pickup_location}</div>
              </div>

              {/* dropoff_fields */}
              {/* {dropoffFields &&
                dropoffFields.map((item, index) => (
                  <div
                    className="flex flex-row w-full sm:w-[80%] md:w-[30%] gl:w-[20%]"
                    key={index}
                  >
                    <div className="flex-1 border broder-gray-200 border-1 p-2">
                      <label className="block mb-2 text-sm font-medium p-1 text-black">
                        {item.label}
                      </label>
                      {item.inputType === "Select" ? (
                        <select
                          className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${
                            clientError?.context?.key === item.label
                              ? "border border-red-500 "
                              : "border border-gray-200 "
                          }`}
                          required={item.type === "required" ? "required" : ""}
                          name={item.label}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dropoff: {
                                ...formData.dropoff,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                        >
                          <option>Choose</option>
                          {item.selectOption.split(",").map((option, i) => (
                            <option key={i} value={option.trim()}>
                              {option.trim()}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${
                            clientError?.context?.key === item.label
                              ? "border border-red-600 "
                              : "border border-gray-200 "
                          }`}
                          required={item.type === "required" ? "required" : ""}
                          name={item.label}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dropoff: {
                                ...formData.dropoff,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                ))} */}
            </div>
          </div>

          <div className="mt-3 mx-3">
            <label className="block mb-2 text-sm font-medium text-black">
              Notes:
            </label>
            <textarea
              className={`text-sm rounded-sm focus:border-gray-200 block w-full p-1 ${clientError?.context?.key === "return_notes"
                  ? "border border-red-600 "
                  : "border border-gray-200 "
                }`}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  return_notes: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
      )}

      <div className="card card-body mb-3 w-full md:w-4/5 mt-5 mx-2 border border-gray-300 p-5">
        <label className="block mb-2 text-xl font-medium text-black">
          Vehicle:-
        </label>
        <div className="flex flex-col lg:flex-row w-full">
          {vehicles && vehicles.length > 0 && selectedVehicle && (
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}${selectedVehicle.image}`}
              className=" flex-1 w-[180px] h-[100px]"
              alt=""
            />
          )}

          <div className="flex-1 border broder-gray-200 border-1 p-2">
            <button
              onClick={toggleDropdown}
              type="button"
              className="text-dark first-line: font-medium rounded-lg text-sm py-2.5 text-center inline-flex items-center w-full"
            >
              <label className="border border-gray-200 border-1 self-center p-1 w-full">
                {vehicles &&
                  vehicles.length > 0 &&
                  selectedVehicle &&
                  selectedVehicle.name}
              </label>
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            {isDropdownOpen && (
              <div
                onMouseLeave={closeDropdown}
                className="z-10 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                  {vehicles &&
                    vehicles.map((item, index) => (
                      <li
                        key={index}
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer dark:hover:text-white"
                        onClick={() => {
                          setSelectedVehicle(item);
                          setFormData({
                            ...formData,
                            vehicle: {
                              ...formData.vehicle,
                              vehicle_id: item.id,
                              vehicle_name: item.name,
                            },
                          });
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>

          {vehicles && vehicles.length > 0 && (
            <>
              <div className="flex-1 border broder-gray-200 border-1 p-2">
                <label className="block mb-2 text-sm font-medium p-1 text-black">
                  Passenger Capacity
                </label>
                <select
                  className="border border-gray-200 text-sm rounded-sm focus:border-gray-200 block w-full p-1"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicle: {
                        ...formData.vehicle,
                        passenger: e.target.value,
                      },
                    });
                  }}
                >
                  {createOptions(selectedVehicle?.passenger_capacity, 1)}
                </select>
              </div>

              <div className="flex-1 border broder-gray-200 border-1 p-2">
                <label className="block mb-2 text-sm font-medium p-1 text-black">
                  Child Seats
                </label>
                <select
                  className="border border-gray-200 text-sm rounded-sm focus:border-gray-200 block w-full p-1"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicle: {
                        ...formData.vehicle,
                        child_seats_capacity: e.target.value,
                      },
                    });
                  }}
                >
                  {createOptions(selectedVehicle?.child_seats_capacity, 0)}
                </select>
              </div>

              <div className="flex-1 border broder-gray-200 border-1 p-2">
                <label className="block mb-2 text-sm font-medium p-1 text-black">
                  Check-in Luggage
                </label>
                <select
                  className="border border-gray-200 text-sm rounded-sm focus:border-gray-200 block w-full p-1"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicle: {
                        ...formData.vehicle,
                        check_in_laggage: e.target.value,
                      },
                    });
                  }}
                >
                  {createOptions(selectedVehicle?.large_luggage_capacity, 0)}
                </select>
              </div>

              <div className="flex-1 border broder-gray-200 border-1 p-2">
                <label className="block mb-2 text-sm font-medium p-1 text-black">
                  Hand Luggage
                </label>
                <select
                  className="border border-gray-200 text-sm rounded-sm focus:border-gray-200 block w-full p-1"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      vehicle: {
                        ...formData.vehicle,
                        hand_laggage: e.target.value,
                      },
                    });
                  }}
                >
                  {createOptions(selectedVehicle?.small_luggage_capacity, 0)}
                </select>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="card card-body mb-3 w-full md:w-4/5 mt-5 mx-2 border border-gray-300 p-5">
        <label className="block mb-2 text-xl font-medium text-black">
          Passenger Details:-
        </label>
        <div className="flex flex-col md:flex-row w-full mt-5">
          <div className="flex-1">
            <label className="block mb-2 text-sm font-medium text-black">
              Full name
            </label>
            <input
              className={`border text-sm rounded-sm focus:border-gray-200 block w-full p-2.5 flex-shrink-0 ${clientError?.context?.key === "customer_name"
                  ? "border border-red-600 "
                  : "border border-gray-200 "
                }`}
              value={appContext?.state.user?.name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  customer_name: e.target.value,
                })
              }
            />
          </div>
          <div className="flex-1 ml-2 mr-2">
            <label className="block mb-2 text-sm font-medium text-black">
              Email Address
            </label>
            <input
              className={`border text-sm rounded-sm focus:border-gray-200 block w-full p-2.5 flex-shrink-0 ${clientError?.context?.key === "customer_email"
                  ? "border border-red-600 "
                  : "border border-gray-200 "
                }`}
              value={appContext?.state.user?.email}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  customer_email: e.target.value,
                })
              }
            />
          </div>
          <div className="flex-1">
            <label className="block mb-2 text-sm font-medium text-black">
              Contact Number
            </label>
            <PhoneInput
              className={`border text-sm rounded-sm focus:border-gray-200 block w-full flex-shrink-0 ${clientError?.context?.key === "notes"
                  ? "border border-red-600 "
                  : "border border-gray-200 "
                }`}
              country={"eg"}
              enableSearch={true}
              inputStyle={{
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  customer_phone: value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="card card-body mb-3 w-full md:w-4/5 mt-5 mx-2 border border-gray-300 p-5">
        <label className="block mb-2 text-xl font-medium text-black">
          Payment:-
        </label>

        <hr />
        <div className="card p-4">
          <div className="text-center">
            <div id="loader" className="hidden">
              <i className="animate-spin"></i>
            </div>
            <h1 className="journeyPriceText my-0">
              FARE:{" "}
              <span id="journeyFareDisplay">
                {formData.isReturn ? booking?.fare * 2 : booking?.fare}
              </span>{" "}
              GBP
            </h1>
          </div>
          <h5 className="mt-3 text-center font-normal mb-3 text-gray-500">
            Payment gateway:
          </h5>
          <div className="flex flex-wrap justify-center mb-3">
            <button
              className={`px-8 py-2 hover:shadow-lg transition duration-300 active text-white ${paymentBtn.Paypal || formData?.payment_mode === "Paypal"
                  ? "bg-primary"
                  : "bg-gray-600 hover:bg-gray-800"
                } cursor-pointer mr-1`}
              onClick={() => handlePaymentMode("Paypal")}
            >
              Pay Pal
            </button>
            <button
              className={`px-8 py-2 hover:shadow-lg transition duration-300 active text-white ${paymentBtn.Stripe
                  ? "bg-primary"
                  : "bg-gray-600 hover:bg-gray-800"
                } cursor-pointer`}
              onClick={() => handlePaymentMode("Stripe")}
            >
              Pay Via Debit/Credit Card
            </button>
          </div>

          <div className="alert alert-info">
            <div className="flex justify-center mb-3 gap-2">
              <div className="w-48">
                <input
                  type="text"
                  id="voucherCode"
                  className="border border-gray-200 text-sm rounded-sm focus:border-gray-200 block w-full p-2.5 flex-shrink-0 cursor-pointer"
                  placeholder="Voucher Code"
                  autoComplete="on"
                  maxLength="15"
                />
              </div>
              <div className="flex items-center" id="voucherApplyDiv">
                <button
                  type="button"
                  id="voucherApply"
                  className="px-8 py-2 hover:shadow-lg transition duration-300 active bg-primary/70 hover:bg-primary/100 text-white cursor-pointer"
                >
                  Apply
                </button>
                <button
                  type="button"
                  id="voucherCancel"
                  className="btn btn-danger hidden"
                >
                  Remove
                </button>
              </div>
            </div>
            <div
              id="voucherMsg"
              className="text-center"
              title='Click "Apply" to update the voucher'
            >
              Click "Apply" to update the voucher
            </div>
          </div>

          <div className="text-center mt-5">
            <p className="mt-2">
              By clicking 'BOOK NOW' button you are agreeing to our{" "}
              <a href="/terms/" target="_blank">
                Terms And Conditions
              </a>{" "}
              &amp;{" "}
              <a href="/privacy-policy/" target="_blank">
                Privacy and Security
              </a>
              .
            </p>
            <p className="mt-2">
              Additional charges may be applicable for any changes in route,
              waiting time, additional stops, address changes or vehicle
              changes.
            </p>
            <input
              type="button"
              className="px-8 py-3 mt-5 hover:shadow-lg transition duration-300 active bg-primary/70 hover:bg-primary/100 text-white cursor-pointer"
              name="bookingType"
              id="btnBook"
              value="BOOK NOW"
              onClick={(e) => handleBookingForm(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
