import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PopularServices from "../components/Popular/PopularServices";
import http from "../config/axios";
import Joi from "joi";
import { useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../context/app-context";
import toast from "react-hot-toast";
import PageHeading from "components/pageHeading/PageHeading";
import bgLogin from '../assets/img/southend-reviews.png'
import Loading from "../components/Shared/Loading";

/** fiedls validator */
const schema = Joi.object({
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    })
    .messages({
      "string.empty": '"Email" is required',
    }),
  password: Joi.required().messages({
    "string.empty": '"Password" is required',
  }),
});
const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [clientError, setClientError] = useState(null);
  const [loading, setLoading] = useState(false);
  const appContext = useAppContext();

  const navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (appContext?.state?.auth) {
      navigate("/booking/list");
    }
  });

  const loginUser = async (formData) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}sanctum/csrf-cookie`)
      .then(async (response) => {
        setLoading(true);
        const res = await http.post("login", {
          email: formData.email,
          password: formData.password,
        });
        const user = JSON.stringify({
          auth: true,
          user: JSON.stringify({
            name: res.data.user.name,
            email: res.data.user.email,
            profileImage: res.data.user.profile_image,
            contact: res.data.user.contact,
          }),
          token: res.data.token,
        });
        localStorage.setItem("persist", user);
        await appContext.dispatch({
          type: appContext.actions.SET,
          data: {
            auth: true,
            user: {
              name: res.data.user.name,
              email: res.data.user.email,
              profileImage: res.data.user.profile_image,
              contact: res.data.user.contact,
            },
            token: res.data.token,
          },
        });

        if (appContext?.state?.auth) {
          navigate("/booking/list");
        }
        setLoading(true);
      })
      .catch((e) => {
        toast.error(e.response.data.error);
        setLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let validator = schema.validate({ ...data });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      loginUser(data);
    }
  };

  return (
    <>
      <PageHeading title="LOGIN" img ={bgLogin}/>
      <div className="login-form-wrapper shadow-xl w-full md:w-[60%] lg:w-2/5 mx-auto p-10 mt-20">
        <form className="px-5 py-5" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row  sm:items-center">
              <label className="text-md font-bold w-2/5">Email</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Email Address"
                name="email"
                onChange={handleChange}
              />
            </div>
            {clientError && clientError?.context?.key === "email" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-small mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-5">
            <div className="flex flex-col sm:flex-row  sm:items-center">
              <label className="text-md font-bold w-2/5">Password</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Password"
                type="password"
                name="password"
                onChange={handleChange}
              />
            </div>
            {clientError && clientError?.context?.key === "password" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-small mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <button
            type="submit"
            className={`simple-gradient-btn uppercase w-full text-white py-3 text-md mt-5 ${loading ? 'disabled': ''}`}
          >
            {
              loading ? (<Loading height="30" width="30" color="#fff"/>) : 'login'
            }
          </button>
        </form>

        <div className="flex flex-row justify-between mt-5">
          <Link className="underline" to="/signup">
            Create An Account?
          </Link>
          <Link className="underline" to="/forgot-password">
            Forgot Password?
          </Link>
        </div>
      </div>

      <div className="mt-20">
        <PopularServices />
      </div>
    </>
  );
};

export default Login;
