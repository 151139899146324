import React from 'react';
// eslint-disable-next-line react/prop-types
const PopularServiceItem = ({ active,item }) => {
  return (
    <div
      className={`w-full md:w-[32%] border-gray-700 hover:bg-[rgb(0,64,128)] hover:text-white transition-all mb-10 px-6 py-12 flex flex-col items-center gap-6 "
      } shadow`}
    >
      <h3 className=" text-xl font-bold">{item.title}</h3>
      <p className=" text-sm text-center">{item.desc}</p>
    </div>
  );
};

export default PopularServiceItem;
