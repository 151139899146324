/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ServicPopup = ({ setShowPopup, setShowMenu }) => {
  return (
    <div
      className=" absolute   top-[130px] lg:top-9 lg:right-[290px] w-[180px] bg-white z-[9999] px-4 py-4 shadow-lg"
      onMouseLeave={() => setShowPopup(false)}
    >
      <ul className=" flex flex-col gap-3">
        <li className=" hover:underline" onClick={() => setShowMenu(false)}>
          <Link
            to="/services/airport"
            className=" text-sm font-[600] text-black hover:underline hover:text-primary"
          >
            Airport Transfers
          </Link>
        </li>
        <li className=" hover:underline" onClick={() => setShowMenu(false)}>
          <Link
            to="/services/hotel"
            className=" text-sm font-[600] text-black hover:underline hover:text-primary"
          >
            Hotel Transfers
          </Link>
        </li>
        <li className=" hover:underline" onClick={() => setShowMenu(false)}>
          <Link
            to="/services/station"
            className=" text-sm font-[600] text-black hover:underline hover:text-primary"
          >
            Station Transfers
          </Link>
        </li>
        <li className=" hover:underline" onClick={() => setShowMenu(false)}>
          <Link
            to="/services/cruise"
            className=" text-sm font-[600] text-black hover:underline hover:text-primary"
          >
            Cruise Port Transfers
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ServicPopup;
