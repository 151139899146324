import React from 'react';
import { Link } from "react-router-dom";
import bg from '../../assets/img/booking-headline.png'

const Bookme = () => {
  return (
    <div className="  w-full p-4 flex justify-center mb-10 h-[30vh] bg-[url('../../assets/img/booking-headline.png')]">
      <div className="w-[1100px] flex justify-center md:justify-between items-center flex-wrap">
        <div className=" flex flex-col text-center  md:text-start">
          <span className=" text-xl md:text-2xl font-[500] uppercase">
            LOOKING FOR
          </span>
          <span className=" text-2xl md:text-3xl font-[600] leading-10">
            Safe & Reliable Taxi Service?
          </span>
        </div>
        <Link
          to="/"
          className="uppercase text-xl md:text-2xl simple-gradient-btn text-white py-3 px-4"
        >
          Book Now
        </Link>
      </div>
    </div>
  );
};

export default Bookme;
