import React from "react";

import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "../components/pageHeading/PageHeading";
import MainHeading from "../components/mainHeading/MainHeading";
import ContactForm from "../components/contactForm/ContactFrom";
import contactImg from "../assets/img/southend-aboutus.png";

const data = [
  {
    title: "GOT A QUESTION?",
    subTitle: "We'd love to talk about how we can help you.",
  },
];

const contact = () => {
  return (
    <>
      <PageHeading title="CONTACT" img={contactImg}/>
      <div className=" mt-14 mb-14">
        <MainHeading data={data} page="contact" />
      </div>
      <ContactForm />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.5179503439017!2d-0.3878871240884184!3d51.54040080829287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766d36d6ab543b%3A0xb68a5c77c2d4cd4e!2sBengarth%20Rd%2C%20Northolt%20UB5%205LF%2C%20UK!5e0!3m2!1sen!2s!4v1693403286113!5m2!1sen!2s"
        className="w-full h-[50vh]"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>

      <PopularServices />
     
    </>
  );
};

export default contact;
