import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Joi from "joi"; // Import Joi for validation

import PopularServices from "../components/Popular/PopularServices";
import http from "config/axios";
import toast from "react-hot-toast";
import PageHeading from "../components/pageHeading/PageHeading";
import bgSignup from "../assets/img/southend-reviews.png";

const schema = Joi.object({
  name: Joi.string()
    .pattern(/^[A-Za-z\s]+$/)
    .min(3)
    .max(30)
    .required()
    .messages({
      "string.empty": '"Name" is required',
    }),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    })
    .messages({
      "string.empty": '"Email" is required',
    }),
  contact: Joi.string().required().messages({
    "string.empty": '"Phone Number" is required',
  }),
  password: Joi.string().min(8).required().messages({
    "string.empty": '"Password" is required',
  }),
  confirm_password: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .messages({
      "string.empty": '"Confirm Password" is required',
      "any.only": '"Confirm Password" must match Password',
    }),
});

const Signup = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validator = schema.validate({ ...data });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);
      http
        .post("signUp", data)
        .then((res) => {
          toast.success(res.data.success);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
        });
    }
  };

  return (
    <>
      <PageHeading title="SIGNUP" img={bgSignup} />
      <div className="login-form-wrapper shadow-xl w-full md:w-[60%] lg:w-2/5 mx-auto p-10 mt-20">
        <form className="px-5 py-5" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <label className="text-md font-bold w-2/5">Name</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleChange}
              />
            </div>
            {clientError && clientError?.context?.key === "name" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-s mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <label className="text-md font-bold w-2/5">Email Address</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Email Address"
                type="email"
                name="email"
                onChange={handleChange}
              />
            </div>
            {clientError && clientError?.context?.key === "email" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-s mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <label className="text-md font-bold w-2/5">Phone</label>
              <PhoneInput
                country={"eg"}
                enableSearch={true}
                inputStyle={{ width: "100%" }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={(value) =>
                  handleChange({ target: { name: "contact", value } })
                }
              />
            </div>
            {clientError && clientError?.context?.key === "contact" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-s mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <label className="text-md font-bold w-2/5">Password</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Password"
                type="password"
                name="password"
                onChange={handleChange}
              />
            </div>
            {clientError && clientError?.context?.key === "password" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-s mt-1">
                {clientError?.message}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <label className="text-md font-bold w-2/5">Re-enter</label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Re-enter Password"
                type="password"
                name="confirm_password"
                onChange={handleChange}
              />
            </div>
            {clientError &&
              clientError?.context?.key === "confirm_password" && (
                <div className="flex items-center font-medium tracking-wide text-red-500 text-s mt-1">
                  {clientError?.message}
                </div>
              )}
          </div>
          <button
            type="submit"
            className="simple-gradient-btn uppercase w-full text-white py-3 text-md mt-5"
          >
            Signup
          </button>
        </form>

        <div className="text-center mt-5">
          <Link className="underline" to="/login">
            Already have an account?
          </Link>
        </div>
      </div>

      <div className="mt-20">
        <PopularServices />
      </div>
    </>
  );
};

export default Signup;
