import React from 'react';
import PopularServiceItem from "./PopuparServiceItem";
import MainHeading from "components/mainHeading/MainHeading";

const data = [
  {
    title: "OUR POPULAR",
    subTitle: "Southend City Transfers",
  },
];

const servicesData = [
  {
    title: "Business trips",
    desc: "Enjoy our cozy rides while you close deals during the drive.",
  },
  {
    title: "Flight runs",
    desc: "We help you catch your flights swiftly and safely which is not less than a flight.",
  },
  {
    title: "Meet & Greet Service",
    desc: "Meet you inside the airport and cruise port terminals with a name board.",
  },
  {
    title: "Weddings",
    desc: "Create memories with your soulmate with us throughout the entire ride!.",
  },
  {
    title: "Condolences",
    desc: "Recall and embrace the memories of your beloved on your way to their funeral.",
  },
  {
    title: "Appointments",
    desc: "Don't miss out on any significant appointments. Either if it is a medical appointment or an interview",
  },
];

const PopularServices = () => {
  return (
    <div
      className={`w-full p-4 sm:p-0 relative flex justify-center bg-[url('../../assets/img/popular.png')]`}
    >
      <div className=" w-full md:max-w-[75%] pt-20 mb-20 flex flex-col gap-12">
        <MainHeading data={data} />
        <div className=" flex justify-between flex-wrap ">
          {servicesData &&
            servicesData.map((item, i) => <PopularServiceItem key={i} item ={item} active={i === 1 ? true : false}/>)}
          
        </div>
      </div>
    </div>
  );
};

export default PopularServices;
