import React from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";

const Footer = ({ settings }) => {
  const data = new Date();
  const year = data.getFullYear();

  return (
    <div className="w-full bg-[rgb(51,51,51)] text-white flex flex-col justify-center items-center  ">
      <div className=" max-w-[1200px]">
        <div className=" flex items-center flex-wrap justify-center  md:justify-start py-9 px-6 gap-3 sm:gap-0">
          <div className=" w-full md:w-[34%]">
            <div className=" w-[52%]">
              <img
                src={require("../../assets/img/southend-city-transfers-logo-white.png")}
                alt=""
                className=" w-full object-cover"
              />
            </div>
            <p className=" pt-4 text-sm">
              Southend City Transfers provide taxi service to help you to find
              the most dependable and highest quality taxi services, anytime and
              anywhere without any problems
            </p>
          </div>
          <div className="w-full md:w-[32%] flex flex-col gap-6 sm:ml-6">
            <h5 className=" font-bold text-[16px]">Useful Links</h5>
            <ul className=" flex justify-between flex-wrap">
              <li className=" flex gap-3 items-center w-[50%]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10px"
                    height="10px"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        fill="#FECA01"
                        d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 4.42927,1.14824 6.42927,2.81491 8.5,4.5C 6.4559,6.21943 4.4559,7.8861 2.5,9.5C 2.16667,9.5 1.83333,9.5 1.5,9.5C 1.5,6.16667 1.5,2.83333 1.5,-0.5 Z"
                      />
                    </g>
                  </svg>
                </span>
                <Link to="/" style={{ color: "#fff" }}>
                  Home
                </Link>
              </li>
              <li className=" flex gap-3 items-center w-[50%]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10px"
                    height="10px"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        fill="#FECA01"
                        d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 4.42927,1.14824 6.42927,2.81491 8.5,4.5C 6.4559,6.21943 4.4559,7.8861 2.5,9.5C 2.16667,9.5 1.83333,9.5 1.5,9.5C 1.5,6.16667 1.5,2.83333 1.5,-0.5 Z"
                      />
                    </g>
                  </svg>
                </span>
                <Link to="/reviews" style={{ color: "#fff" }}>
                  Reviews
                </Link>
              </li>
              <li className=" flex gap-3 items-center w-[50%]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10px"
                    height="10px"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        fill="#FECA01"
                        d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 4.42927,1.14824 6.42927,2.81491 8.5,4.5C 6.4559,6.21943 4.4559,7.8861 2.5,9.5C 2.16667,9.5 1.83333,9.5 1.5,9.5C 1.5,6.16667 1.5,2.83333 1.5,-0.5 Z"
                      />
                    </g>
                  </svg>
                </span>
                <Link to="/about" style={{ color: "#fff" }}>
                  {" "}
                  About Us
                </Link>
              </li>
              <li className=" flex gap-3 items-center w-[50%] mt-3">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10px"
                    height="10px"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        fill="#FECA01"
                        d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 4.42927,1.14824 6.42927,2.81491 8.5,4.5C 6.4559,6.21943 4.4559,7.8861 2.5,9.5C 2.16667,9.5 1.83333,9.5 1.5,9.5C 1.5,6.16667 1.5,2.83333 1.5,-0.5 Z"
                      />
                    </g>
                  </svg>
                </span>
                <Link to="/blog" style={{ color: "#fff" }}>
                  Blog
                </Link>
              </li>
              <li className=" flex gap-3 items-center w-[50%] ">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10px"
                    height="10px"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        fill="#FECA01"
                        d="M 1.5,-0.5 C 1.83333,-0.5 2.16667,-0.5 2.5,-0.5C 4.42927,1.14824 6.42927,2.81491 8.5,4.5C 6.4559,6.21943 4.4559,7.8861 2.5,9.5C 2.16667,9.5 1.83333,9.5 1.5,9.5C 1.5,6.16667 1.5,2.83333 1.5,-0.5 Z"
                      />
                    </g>
                  </svg>
                </span>
                <Link to="/services" style={{ color: "#fff" }}>
                  Services
                </Link>
              </li>
            </ul>
          </div>
          <div className=" w-full md:w-[30%]  flex flex-col gap-6">
            <h5 className=" font-bold text-[16px]">Contact Info</h5>
            <div className=" flex flex-col gap-2">
              <Link
                to={`mailto:${settings && settings.email_address ? settings.email_address : 'booking@southendcitytransfer.co.uk'}`}
                className="flex items-center gap-3"
              >
                <div>
                  <MdOutlineMailOutline
                    className=" text-yellow-400"
                    size={18}
                  />
                </div>
                <span className=" text-inherit">
                  <span className=" text-white">
                    {settings && settings.email_address ? settings?.email_address : 'booking@southendcitytransfer.co.uk'}
                  </span>
                </span>
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${settings && settings.contact_number ? settings.contact_number : '+442080049002'}&text=Hi`}
                className="text-inherit flex items-center gap-3"
              >
                <div>
                  <FaPhoneAlt className=" text-yellow-400" size={18} />
                </div>
                <span>{settings && settings.contact_number ? settings?.contact_number : '+442080049002'}</span>
              </Link>
              <div className=" flex items-center gap-3">
                <div>
                  <IoLocationSharp className=" text-yellow-400" size={23} />
                </div>
                <span>{settings && settings.company_address ? settings?.company_address : 'Bengarth Road Northolt Ub5 5lf'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className=" p-2 text-sm border-t w-full text-center">
        {year} Southend City Transfer. All Rights Reserved.
      </span>
    </div>
  );
};

export default Footer;
