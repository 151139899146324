import React from 'react';
import ServiceItem from "./ServiceItem";
import cruisImg from '../../assets/img/cruise-port-tranfers.png'
import airportImg from '../../assets/img/airport-transfers.png'
import hotelImg from '../../assets/img/hotel-transfers.png'
import stationImg from '../../assets/img/station-transfers.png'


const data = [
  {
    img: cruisImg,
    title: "Cruise Port Tranfers",
    url: "cruise",
    description:
      "We provide meet-and-greet services at all of the main seaports in London, including Southampton Cruise Port, Dover Cruise Port, London Tilbury Cruise Port, Harwich Cruise Port",
  },
  {
    img: airportImg,
    title: "Airport Transfers",
    url: "airport",
    description:
      "You can arrange your trip with our Heathrow airport taxi service to Marriott Hotel in London or any other hotel or your home before you arrive at the airport",
  },
  {
    img: hotelImg,
    title: "Hotel Transfers",
    url: "hotel",
    description:
      "Our airport-to-hotel transfer service allows you to arrive in comfort and luxury at your important business meeting, any tourist attraction, any hotel, or your home.",
  },
  {
    img: stationImg,
    title: "Station Transfers",
    url: "station",
    description:
      "We are a dynamic minicab company with vast expertise in the area; our team of experienced drivers is committed to providing you with high-quality transportation",
  },
];

const Services = () => {
  return (
    <div className=" w-full flex items-center justify-center mt-32 mb-[130px]">
      <div className="  max-w-[1250px] ">
        <div className=" flex justify-center items-center flex-col">
          <h5 className=" text-[14px] lg:text-xl font-bold uppercase tracking-wider text-primary">
            OUR SERVICES
          </h5>
          <h3 className="text-[20px] lg:text-3xl font-bold leading-10 tracking-wider">
            Our Transfer Services
          </h3>
        </div>
        <div className=" flex  mt-10 flex-wrap justify-center lg:justify-between">
          {data && data.map((item, i) => <ServiceItem key={i} item = {item} active={i === 1 ? true : false}/>)}
          
        </div>
      </div>
    </div>
  );
};

export default Services;
