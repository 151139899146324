// eslint-disable-next-line react/prop-types
import React from "react";
import { Link } from "react-router-dom";
const ServiceItem = ({ active, item }) => {
  return (
    <div
      className={`w-full  md:w-[40%]  lg:w-[24%] flex flex-col gap-6 hover:bg-black hover:text-white transition-all  items-center justify-center `}
    >
      <div className=" w-full h-[200px]">
        <img src={item.img} alt="" className=" w-full object-cover h-full" />
      </div>
      <h5 className=" text-[18px] font-bold">{item.title}</h5>
      <p className={`text-sm text-center hover:text-white  "text-gray-500"  `}>
        {item.description}
      </p>
      <Link to={`/services/${item.url}`} className=" text-yellow-400 underline">
        Learn more
      </Link>
    </div>
  );
};

export default ServiceItem;
