import React, { useState } from "react";
import { Link } from "react-router-dom";

import PopularServices from "../components/Popular/PopularServices";
import PageHeading from "components/pageHeading/PageHeading";
import bgForgotPassword from "../assets/img/southend-reviews.png";
import http from "../config/axios";
import toast from "react-hot-toast";
import Joi from "joi";
import Loading from "../components/Shared/Loading";


const schema = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": '"Email" is required',
      "string.email": '"Email" must be a valid email address'
    })
});
const ForgotPassword = () => {

  const [email, setEmail] = useState("");
  const [clientError, setClientError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validator = schema.validate({email});
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setLoading(true);
      await http
        .post("/forgotPassword", {email:email})
        .then((res) => {
          setLoading(false);
        })
        .catch((err) =>{
          err.response.data.error.email ? 
          toast.error(err.response.data.error.email)
          : toast.error(err.response.data.error);
          setLoading(false);
        });
    }
  }
  return (
    <>
      <PageHeading title="FORGOT PASSWORD?" img={bgForgotPassword} />
      <div className="login-form-wrapper shadow-xl w-full md:w-[60%] lg:w-2/5 mx-auto p-10 mt-20">
        <form className="px-5 py-5" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row sm:items-center mb-5">
            <label className="text-md font-bold w-2/5">Email</label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {clientError && clientError?.context?.key === "email" && (
              <div className="flex items-center font-medium tracking-wide text-red-500 text-small mt-1">
                {clientError?.message}
              </div>
            )}
           <button
            type="submit"
            className={`simple-gradient-btn uppercase w-full text-white py-3 text-md mt-5 ${loading ? 'disabled': ''}`}
          >
            {
              loading ? (<Loading height="30" width="30" color="#fff"/>) : ' RESET PASSWORD'
            }
          </button>
        </form>

        <div className="mt-3 text-center">
          <Link className="underline" to="/login">
            Login?
          </Link>
        </div>
      </div>

      <div className="mt-20">
        <PopularServices />
      </div>
    </>
  );
};

export default ForgotPassword;
